/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const OrderDetailInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.user?.profilePicture ? (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl(`${user.user?.profilePicture}`)}
              alt={user.user.fullName}
              className='w-100'
            />
          </div>
        ) : (
          <div className='symbol-label'>
            <img
              src={
                'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
              }
              alt={user?.user?.fullName}
              className='w-100'
            />
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {user?.user?.fullName}
      </a>
      <span>{user?.user?.email}</span>
    </div>
  </div>
)

export {OrderDetailInfoCell}
