/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {Link, useLocation} from 'react-router-dom'

type Props = {
  id: ID
}
// const sampleData = [
//   {
//     id: '1',
//     status: 'active',
//   },
//   {
//     id: '2',
//     status: 'inactive',
//   },
//   {
//     id: '3',
//     status: 'active',
//   },
//   {
//     id: '4',
//     status: 'active',
//   },
//   {
//     id: '5',
//     status: 'inactive',
//   },
//   {
//     id: '6',
//     status: 'active',
//   },
//   {
//     id: '7',
//     status: 'active',
//   },
//   {
//     id: '8',
//     status: 'active',
//   },
//   {
//     id: '9',
//     status: 'inactive',
//   },
//   {
//     id: '10',
//     status: 'active',
//   },
//   {
//     id: '11',
//     status: 'active',
//   },
//   {
//     id: '12',
//     status: 'active',
//   },
//   {
//     id: '13',
//     status: 'active',
//   },
//   {
//     id: '14',
//     status: 'inactive',
//   },
//   {
//     id: '15',
//     status: 'active',
//   },
//   {
//     id: '16',
//     status: 'inactive',
//   },
//   {
//     id: '17',
//     status: 'active',
//   },
//   {
//     id: '18',
//     status: 'active',
//   },
//   {
//     id: '19',
//     status: 'active',
//   },
//   {
//     id: '20',
//     status: 'active',
//   },
// ]
const StoreStatusCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const location = useLocation()
  const screen = location.pathname.split('/')[3]
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  return (
    <>
      <div className='fw-bolder'>{id ? 'Inactive' : 'Active'}</div>
    </>
  )
}

export {StoreStatusCell}
