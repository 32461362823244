import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {
  IMageUpload,
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useCreateProfuct} from '../../core/QueryResponseProvider'
import toast from 'react-hot-toast'
import {uploadImagesToS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {useNavigate} from 'react-router-dom'

const profileDetailsSchema = Yup.object().shape({
  productName: Yup.string().required('productName is required'),
  productDescription: Yup.string().required('product Description is required'),
  productPrice: Yup.string().required('product Price is required'),
  productCode: Yup.string().required('product Code is required'),
  productHsnCode: Yup.string().required('product hsn code is required'),
  productTax: Yup.string().required('product tax is required'),
})

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const {mutate, isError, isLoading, isSuccess} = useCreateProfuct()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState<IMageUpload[]>([])
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('multiple', '')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        console.log(selectedFile)
        try {
          const data = await uploadImagesToS3(selectedFile)
          console.log(data)
          if (data) {
            let newData = data.map((da) => {
              return {Location: da.Location}
            })
            setProfilePicture(newData)
          }
          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          console.log(error)

          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (profilePicture) {
          values.productPicture = profilePicture
        }
        await mutate(values)
        toast.success('Product add successfully', {id: '30'})
        setTimeout(() => {
          navigate('/apps/product-management/products')
        }, 2000)
        setData(initialValues)
      } catch (error) {
        toast.error('Something went wrong', {id: '30'})
      }

      setTimeout(() => {
        setData(values)
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Product</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Product image</label>
              {profilePicture.length > 0 ? (
                <div className='col-lg-8'>
                  {profilePicture.map((image) => {
                    return (
                      <div
                        key={image.Location}
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      >
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{
                            backgroundImage: `url(${image.Location})`,
                          }}
                          onClick={() => handleGetTheImageFromLocal()}
                        ></div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product name'
                  {...formik.getFieldProps('productName')}
                />
                {formik.touched.productName && formik.errors.productName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product code / SKU</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product code'
                  {...formik.getFieldProps('productCode')}
                />
                {formik.touched.productCode && formik.errors.productCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Hsn Code</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product Hsn Code'
                  {...formik.getFieldProps('productHsnCode')}
                />
                {formik.touched.productHsnCode && formik.errors.productHsnCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productHsnCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Tax</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('productTax')}
                >
                  <option value=''>--select tax--</option>
                  <option value='5'>5</option>
                  <option value='12'>12</option>
                  <option value='18'>18</option>
                  <option value='28'>28</option>
                </select>
                {formik.touched.productTax && formik.errors.productTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productTax}</div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product ID'
                  {...formik.getFieldProps('productId')}
                />
                {formik.touched.productId && formik.errors.productId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productId}</div>
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product serial number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product serial number'
                  {...formik.getFieldProps('productSerialNumber')}
                />
                {formik.touched.productSerialNumber && formik.errors.productSerialNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productSerialNumber}</div>
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product model number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product model number'
                  {...formik.getFieldProps('productModalNumber')}
                />
                {formik.touched.productModalNumber && formik.errors.productModalNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productModalNumber}</div>
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product SKU</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product SKU'
                  {...formik.getFieldProps('productSku')}
                />
                {formik.touched.productSku && formik.errors.productSku && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productSku}</div>
                  </div>
                )}
              </div>
            </div> */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product Description'
                  {...formik.getFieldProps('productDescription')}
                />
                {formik.touched.productDescription && formik.errors.productDescription && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productDescription}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Price</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Price'
                  {...formik.getFieldProps('productPrice')}
                />
                {formik.touched.productPrice && formik.errors.productPrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productPrice}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add Product'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
