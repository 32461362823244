/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {useGetUserById} from './core/QueryResponseProvider'

export function Overview() {
  const {id} = useParams()
  const {data, isFetching} = useGetUserById(id)
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>User Details</h3>
          </div>

          <Link
            to={`/apps/user-details/${id}/settings`}
            className='btn btn-primary align-self-center'
          >
            Edit User
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{data?.fullName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Phone</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{data?.phone}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Customer Additional Phone</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{data?.additionalPhoneNumber}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email Id</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{data?.email}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Near By Branch
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {data?.nearByBranch
                  ? `${data?.nearByBranch?.storeName}, ${data?.nearByBranch?.storeState}, ${data?.nearByBranch?.storeCity}, ${data?.nearByBranch?.storePincode}, ${data?.nearByBranch?.storeCountry}`
                  : ''}
              </span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Customer type</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{data?.customerType}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Customer Address</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{`${data?.customerAddress}, ${data?.locality}, ${data?.city}, ${data?.state}, ${data?.country}, ${data?.pincode}`}</span>
            </div>
          </div>
          {/* <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Customer Locality</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{data?.locality}</span>
            </div>
          </div> */}
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Customer LandMark</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{data?.landmark}</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}
    </>
  )
}
