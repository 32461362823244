import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TranscationsListHeader} from './components/header/TranscationsListHeader'
import {TranscationsTable} from './table/TranscationsTable'

import {KTCard} from '../../../../../_metronic/helpers'

const TranscationsList = () => {
  return (
    <>
      <KTCard>
        <TranscationsListHeader />
        <TranscationsTable />
      </KTCard>
    </>
  )
}

const TranscationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TranscationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TranscationsListWrapper, TranscationsList}
