import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProductsListHeader} from './components/header/ProductsListHeader'
import {ProductsTable} from './table/ProductsTable'

import {KTCard} from '../../../../../_metronic/helpers'

const ProductsList = () => {
  return (
    <>
      <KTCard>
        <ProductsListHeader />
        <ProductsTable />
      </KTCard>
    </>
  )
}

const ProductsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProductsListWrapper}
