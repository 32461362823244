/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useQueryResponseLoading,
  useQueryResponsePagination,
  useQueryTotalCount,
} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useState} from 'react'

const StoresListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const totalCount = useQueryTotalCount()
  const [currentPage, setCurrentPage] = useState(1)
  const {updateState, state} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    setCurrentPage(page)
    updateState({page, items_per_page: pagination.items_per_page || 10})
  }
  const generateNumbers = (len: number) => {
    let arr: number[] = []
    if (len > 10) {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    } else {
      for (let i = 0; i < len / 2; i++) {
        arr.push(i + 1)
      }
    }
    return arr
  }
  const limit = state.items_per_page ? state.items_per_page : 10
  let recordLength = totalCount ? totalCount : 10

  return (
    <nav aria-label='Page navigation example'>
      <ul className='pagination'>
        <li className='page-item'>
          <button
            disabled={currentPage === 1}
            className='page-link'
            onClick={() => updatePage(currentPage - 1)}
          >
            Previous
          </button>
        </li>
        {generateNumbers(Math.ceil(recordLength / limit)).map((data) => {
          return (
            <li className='page-item'>
              <a
                onClick={() => updatePage(data)}
                className={`page-link ${currentPage === data ? 'active' : ''}`}
                href='#'
              >
                {data}
              </a>
            </li>
          )
        })}

        <li className='page-item'>
          <button
            disabled={currentPage === Math.ceil(recordLength / limit)}
            className='page-link'
            onClick={() => updatePage(currentPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  )
}

export {StoresListPagination}
