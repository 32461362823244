import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ServicesListWrapper} from './users-list/ServicesList'
// /apps/report-management/reports/
const ServiceManagementBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report Complaint Management',
    path: '/apps/report-management/report/complaints',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportServicesManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='complaint'
          element={
            <>
              <PageTitle breadcrumbs={ServiceManagementBreadcrumbs}>
                Complaint Report Management
              </PageTitle>
              <ServicesListWrapper />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to='/apps/report-management/report/complaints/complaint' />}
      />
    </Routes>
  )
}

export default ReportServicesManagementPage
