/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {QueryClient, useQueryClient} from 'react-query'
import {useGetProductById} from './core/QueryResponseProvider'

export function Overview() {
  const {id} = useParams()
  const {data} = useGetProductById(id)

  return (
    <>
      {data ? (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Details</h3>
            </div>

            <Link
              to={`/apps/product-detail/${id}/settings`}
              className='btn btn-primary align-self-center'
            >
              Edit Profile
            </Link>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.productName}</span>
              </div>
            </div>

            {/* <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product ID</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.productId}</span>
              </div>
            </div> */}
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product Code / SKU</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.productCode}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product Tax</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>
                  {data?.productTax ? `${data?.productTax}%` : ''}
                </span>
              </div>
            </div>

            {/* <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product SKU</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{data?.productSku}</span>
              </div>
            </div> */}

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Product Description</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.productDescription}</span>
              </div>
            </div>

            <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Price</label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>{data?.productPrice}</span>
              </div>
            </div>
            <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Status</label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>{data?.is_deactivated ? 'Deactive' : 'Active'}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>No record found</div>
      )}
    </>
  )
}
{
  /* <div className='row gy-10 gx-xl-10'>
  <div className='col-xl-6'>
    <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
  </div>

  <div className='col-xl-6'>
    <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
  </div>
</div>

<div className='row gy-10 gx-xl-10'>
  <div className='col-xl-6'>
    <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
  </div>

  <div className='col-xl-6'>
    <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
  </div>
</div> */
}
