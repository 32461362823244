import {useEffect, useMemo, useState} from 'react'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getAllEmployees} from '../../core/_requests'
import {useAuth} from '../../../../../../auth'
import {User as TechnitionData} from '../../../../../../apps/employee-management/employes-list/core/_models'
import {DateRangePicker, RangeKeyDict} from 'react-date-range'
import moment from 'moment'
import {reportComplaintsDetail} from '../../../../../../../../_metronic/helpers/constants'

const ServicesListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {auth, currentUser} = useAuth()
  const [status, setStatus] = useState<string | undefined>()
  const [employee, setEmployee] = useState<string | undefined>()
  const [employees, setEmployees] = useState<TechnitionData[] | undefined>()
  const [isDateCLick, setIsDateClick] = useState(false)

  const [dateState, setDateState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  useEffect(() => {
    MenuComponent.reinitialization()
    const fetchEmployee = async () => {
      const data = await getAllEmployees(auth?.access_token)
      setEmployees(data)
    }
    fetchEmployee()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setDateState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    })
    setEmployee('')
    setStatus('')
    setIsDateClick(false)
    localStorage.removeItem(reportComplaintsDetail)
  }

  const filterData = () => {
    if (isDateCLick) {
      updateState({
        filter: {
          status: status ? status : undefined,
          startDate: dateState.startDate,
          endDate: dateState.endDate,
          employee: employee ? employee : undefined,
        },
        ...initialQueryState,
      })
    } else {
      updateState({
        filter: {
          status: status ? status : undefined,
          startDate: undefined,
          endDate: undefined,
          employee: employee ? employee : undefined,
        },
        ...initialQueryState,
      })
    }
  }

  const handleSelect = (rangesByKey: RangeKeyDict) => {
    if (
      rangesByKey.selection.startDate &&
      rangesByKey.selection.endDate &&
      rangesByKey.selection.key
    ) {
      setDateState({
        startDate: rangesByKey.selection.startDate,
        endDate: rangesByKey.selection.endDate,
        key: rangesByKey.selection.key,
      })
      setIsDateClick(true)
    }
  }
  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown w-500px h-500px overflow-scroll w-md-50'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value=''></option>
              <option value='All'>All</option>
              <option value='Pending'>Pending</option>
              <option value='Approved'>Approved</option>
              <option value='progress'>Progress</option>
              <option value='Completed by employee'>Completed by employee</option>
              <option value='Approved by admin'>Approved by admin</option>
              <option value='Rejected'>Rejected</option>
            </select>
          </div>
          {currentUser && currentUser.user.is_admin && (
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Employee :</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setEmployee(e.target.value)}
                value={employee}
              >
                <option value={undefined}></option>
                <option value='All'>All</option>
                {employees && employees?.length > 0 ? (
                  employees?.map((emp) => {
                    return (
                      <option onClick={() => setEmployee(emp._id)} key={emp._id} value={emp._id}>
                        {emp.fullName}
                      </option>
                    )
                  })
                ) : (
                  <option value=''>no employee found</option>
                )}
              </select>
            </div>
          )}
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <DateRangePicker ranges={[dateState]} onChange={handleSelect} />
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ServicesListFilter}
