import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  useGetEmployees,
  useGetServiceById,
  useUpdateServiceEmployeeById,
} from './core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {useAuth} from '../../auth'

const EmployeeAllocation = () => {
  const {id} = useParams()
  const {data, isFetching} = useGetEmployees()
  const {data: service, refetch} = useGetServiceById(id)
  const {currentUser} = useAuth()

  console.log('this is the service one', service?.allocatedTechnication?.fullName)

  const {mutate, isSuccess, isLoading, isError} = useUpdateServiceEmployeeById(id)
  const handleEmployeeAllocation = async (id: any, branchId: any) => {
    if (service && service.status !== 'Approved by admin') {
      try {
        const updaData = {
          allocatedTechnication: id,
          allocatedBranch: branchId,
        }
        await mutate(updaData)
        refetch()
        toast.success(isSuccess ? 'technitian allocated successfully' : 'Please wait...')
      } catch (error) {
        toast.error(
          isError
            ? 'Error while update the technitian'
            : 'Internal server error please try again later'
        )
      }
    } else {
      if (service?.status === 'Completed by employee' && currentUser?.user.is_admin) {
        try {
          const updaData = {
            allocatedTechnication: id,
            allocatedBranch: branchId,
          }
          await mutate(updaData)
          refetch()
          toast.success(isSuccess ? 'technitian allocated successfully' : 'Please wait...')
        } catch (error) {
          toast.error(
            isError
              ? 'Error while update the technitian'
              : 'Internal server error please try again later'
          )
        }
      } else {
        toast.error('No one can update this')
      }
    }
  }
  if (data) {
    return (
      <>
        <a
          href='#'
          className='btn btn d-flex align-item-center btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Employee
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>
        {/* begin::Menu */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 h-200px overflow-auto w-250px py-4'
          data-kt-menu='true'
        >
          {data.map((emp) => {
            return (
              <div
                id={`${emp._id}/${emp.branch?._id}`}
                onClick={() => handleEmployeeAllocation(emp._id, emp.branch?._id)}
                className='menu-item px-3 my-1'
              >
                <div
                  className={`${
                    service?.allocatedTechnication?._id === emp._id
                      ? 'activeEmployee bg-secondary'
                      : ''
                  } d-flex p-1 align-items-center`}
                >
                  {/* begin:: Avatar */}
                  <div
                    className='symbol symbol-circle symbol-50px overflow-hidden me-3'
                    id={emp._id}
                  >
                    <a href='#'>
                      {emp.profilePicture ? (
                        <div className='symbol-label'>
                          <img src={emp.profilePicture} alt={emp.fullName} className='w-100' />
                        </div>
                      ) : (
                        <div className='symbol-label'>
                          <img
                            src={
                              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTucaZx_5ilAhuxxb9dw268yscUKX_ZQivmXqkoS-E&s'
                            }
                            alt={emp.fullName}
                            className='w-100'
                          />
                        </div>
                      )}
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      {emp.fullName}
                    </a>
                    <span>{emp.designation}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  } else {
    return <div>{isFetching ? 'Please wait...' : 'No records found'}</div>
  }
}

export default EmployeeAllocation
