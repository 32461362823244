import React, {FC, createContext, useContext, useState} from 'react'

// Define the DefaultValues interface
interface DefaultValues {
  startDate: string | null | undefined
  endDate: string | null | undefined
  store: string | null | undefined
  employee: string | null | undefined
  status?: string | null | undefined
  updateStartEndDate: (
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    store: string | null | undefined,
    employee: string | null | undefined
  ) => void
}

const defaultValues: DefaultValues = {
  startDate: null,
  endDate: null,
  store: null,
  employee: null,
  updateStartEndDate: () => {},
}

// Create the context
const StartAndEndDateContext = createContext<DefaultValues>(defaultValues)
interface StartAndEndDateProviderProps {
  children: React.ReactNode // Define the type for 'children'
}
// Create a Context Provider component
const StartAndEndDateProvider: FC<StartAndEndDateProviderProps> = ({children}) => {
  // Define default values for user and product
  const [startDate, setStartDate] = useState<string | null | undefined>(null)
  const [endDate, setEndDate] = useState<string | null | undefined>(null)
  const [store, setStore] = useState<string | null | undefined>(null)
  const [employee, setEmployee] = useState<string | null | undefined>(null)

  const updateStartEndDate = (
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    store: string | null | undefined,
    employee: string | null | undefined
  ) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setStore(store)
    setEmployee(employee)
  }
  const data: DefaultValues = {
    startDate,
    updateStartEndDate,
    endDate,
    store,
    employee,
  }
  return <StartAndEndDateContext.Provider value={data}>{children}</StartAndEndDateContext.Provider>
}
const useStartAndEndDateProvider = () => {
  return useContext(StartAndEndDateContext)
}
export {StartAndEndDateContext, StartAndEndDateProvider, useStartAndEndDateProvider}
