import moment from 'moment'
import {FC} from 'react'

type Props = {
  startDate?: string
}

const StartDate: FC<Props> = ({startDate}) => (
  <div className='fw-bolder'>{moment(startDate).format('MM/DD/YYYY')}</div>
)

export default StartDate
