import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Product, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/product`

const createProduct = (
  product: Product,
  token: string | undefined
): Promise<Product | undefined> => {
  return (
    axios
      .post(`${USER_URL}/register`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        product,
      })
      // .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<Product>) => response.data)
  )
}

export {createProduct}
