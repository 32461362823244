import {FC} from 'react'

type Props = {
  two_steps?: boolean
}

const EmployeDesignation: FC<Props> = ({two_steps}) => (
  <>
    <div className='fw-bolder'>{two_steps}</div>
  </>
)

export {EmployeDesignation}
