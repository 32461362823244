import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useGetServiceById} from '../core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {uploadImagesToS3} from '../../../../../_metronic/helpers/uitils/functions'
import {updateService, updateServiceNote} from '../core/_requests'
import {useAuth} from '../../../auth'
interface Images {
  Location: string
}
const ProductImages = () => {
  const {id} = useParams()
  const {data, isFetching, refetch} = useGetServiceById(id)
  const [warrantyCardImages, setWarrantyCardImages] = useState<Images[]>([])
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const {auth} = useAuth()
  useEffect(() => {
    if (data?.warrantyCardImages) {
      setWarrantyCardImages(data?.warrantyCardImages)
    }
  }, [])
  const deleteSelectedImage = (id: string) => {
    console.log('delete image ', id)

    let newImage = warrantyCardImages?.filter((data) => data.Location !== id)

    setWarrantyCardImages(newImage)
  }
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('multiple', '')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          const data = await uploadImagesToS3(selectedFile)
          console.log(data)
          if (data) {
            let newData = data.map((da) => {
              return {Location: da.Location}
            })

            // setUploadImages()
            if (warrantyCardImages && warrantyCardImages.length > 0) {
              for (let index = 0; index < warrantyCardImages.length; index++) {
                const element = warrantyCardImages[index]
                newData.push(element)
              }
            }
            // setSelectedProductImage(newData)
            setWarrantyCardImages(newData)
            console.log('this is the data new image', newData)
          }
          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          console.log(error)

          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  const handleUpdateImages = async () => {
    if (warrantyCardImages.length > 0) {
      try {
        let newServiceImages = await updateService(auth?.access_token, id, {
          warrantyCardImages: warrantyCardImages,
        })
        if (newServiceImages) {
          toast.success('Images update successfully')
        }
      } catch (error) {
        toast.error('somethinw went wrong')
      }
    }
  }
  return (
    <>
      {/* <div className='card mb-5 mb-xl-10 container'>
        <div
          className='card-header border-0 cursor-pointer'
          // role="button"
          // data-bs-toggle="collapse"
          // data-bs-target="#kt_account_profile_details"
          // aria-expanded="true"
          // aria-controls="kt_account_profile_details"
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Product Images</h3>
          </div>
        </div>
        <div className='d-flex justify-content-evenly flex-wrap'>
          {data?.product?.productPicture && data.product.productPicture.length > 0 ? (
            <div className='d-flex justify-content-evenly flex-wrap'>
              {data.product.productPicture.map((image) => {
                return (
                  <div className='row mb-6'>
                    <div key={image._id} className='col-lg-8'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      >
                        <div
                          className='image-input-wrapper w-300px h-300px'
                          style={{
                            backgroundImage: `url(${image.Location})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`}}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className='d-flex justify-content-center flex-wrap card '>
        {warrantyCardImages && warrantyCardImages.length > 0 ? (
          <div className='d-flex justify-content-evenly flex-wrap'>
            {warrantyCardImages.map((image: any) => {
              return (
                <div className='row mb-6'>
                  <div key={image.Location} className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                    >
                      <div
                        className='image-input-wrapper w-300px h-300px position-releative'
                        style={{
                          backgroundImage: `url(${image.Location})`,
                        }}
                      >
                        <i
                          onClick={() => deleteSelectedImage(image.Location)}
                          className='bi bi-trash-fill text-danger text-end btn'
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                  }}
                  onClick={() => handleGetTheImageFromLocal()}
                >
                  <div
                    className='image-input-wrapper w-300px h-300px'
                    style={{
                      backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='row mb-6'>
            <div className='col-lg-8'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
              >
                <div
                  className='image-input-wrapper w-300px h-300px'
                  onClick={() => handleGetTheImageFromLocal()}
                  style={{
                    backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {warrantyCardImages.length > 0 && (
        <div className=' d-flex justify-content-end'>
          <button onClick={() => handleUpdateImages()} type='button' className='btn btn-primary'>
            Update
          </button>
        </div>
      )}
    </>
  )
}

export default ProductImages
