import {useMutation, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {
  getServiceById,
  updateServiceEmp,
  getAllEmployees,
  updateService,
  createNote,
  getNote,
} from './_requests'
import {useAuth} from '../../../auth'
import {UpdateTechnition, Service, CreateNote} from './_models'

export const useGetServiceById = (id: any) => {
  const {auth} = useAuth()
  // useEffect(() => {
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.SERVICE_LIST_BY_ID}-${id}`,
    () => {
      return getServiceById(id, auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // }, [])
  return {isFetching, refetch, data}
}
export const useGetNotes = () => {
  const {auth} = useAuth()
  // useEffect(() => {
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.NOTES_LIST}`,
    () => {
      return getNote(auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // }, [])
  return {isFetching, refetch, data}
}
export const useGetEmployees = () => {
  const {auth} = useAuth()
  // useEffect(() => {
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.EMPLOYEE_LIST}`,
    () => {
      return getAllEmployees(auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // }, [])
  return {isFetching, refetch, data}
}
export const useUpdateServiceEmployeeById = (id: string | undefined) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError} = useMutation(
    (data: UpdateTechnition) => updateServiceEmp(auth?.access_token, id, data),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.SERVICE_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError}
}
export const useUpdateServiceId = (id: string | undefined) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError} = useMutation(
    (data: Service) => updateService(auth?.access_token, id, data),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.SERVICE_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError}
}
export const useCreateNote = (id: any) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError} = useMutation(
    (data: CreateNote) => createNote(auth?.access_token, data),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.SERVICE_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError}
}
