import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Product, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const UPDATE_URL = `${API_URL}/product`
const GET_PRODUCT_URL = `${API_URL}/product`

const getProductById = (id: string, token: string | undefined): Promise<Product | undefined> => {
  return axios
    .get(`${GET_PRODUCT_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
const updateProduct = (token: string | undefined, data: Product): Promise<Product | undefined> => {
  return axios
    .put(`${UPDATE_URL}/${data._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
export {getProductById, updateProduct}
