import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {DashBoardCounts, ServicesQueryResponse, Store, filter} from './_models'
import {User as TechnitionData} from '../../../../modules/apps/employee-management/employes-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_SERVICES_URL = `${API_URL}/service`
const GET_ALL_SERVICES = `${API_URL}/service`
const GET_STORES_URL = `${API_URL}/store`
const GET_EMPLOYEES_URL = `${API_URL}/employee`
const GET_EMPLOYEE_URL = `${API_URL}/employee`
const getServices = (): Promise<DashBoardCounts> => {
  return axios
    .get(`${GET_SERVICES_URL}/alldata`)
    .then((d: AxiosResponse<DashBoardCounts>) => d.data)
}
// const getAllServices = (query: string):
const getAllServices = (): Promise<ServicesQueryResponse> => {
  return axios.get(`${GET_SERVICES_URL}`).then((d: AxiosResponse<ServicesQueryResponse>) => d.data)
}
const filterService = (
  filter: filter,
  token: string | undefined
): Promise<ServicesQueryResponse> => {
  return axios
    .get(
      `${GET_SERVICES_URL}/filter?store=${filter.store ? filter.store : 'undefined'}&startDate=${
        filter.startDate ? filter.startDate : 'undefined'
      }&endDate=${filter.endDate ? filter.endDate : 'undefined'}&employee=${
        filter.employee ? filter.employee : 'undefined'
      }&status=undefined`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((d: AxiosResponse<ServicesQueryResponse>) => d.data)
}
const getAllStores = (token: string | undefined): Promise<Array<Store> | undefined> => {
  return axios
    .get(`${GET_STORES_URL}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<Store>>>) => response.data)
    .then((response: Response<Array<Store>>) => response.data)
}
const filterEmployees = (
  branch: string | undefined,
  token: string | undefined
): Promise<Array<TechnitionData> | undefined> => {
  return axios
    .get(`${GET_EMPLOYEES_URL}/filter?designation=undefined&branch=${branch}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<Response<Array<TechnitionData>>>) => d.data)
    .then((d: Response<Array<TechnitionData>>) => d.data)
}
const getAllEmployees = (token: string | undefined): Promise<Array<TechnitionData> | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<TechnitionData>>>) => response.data)
    .then((response: Response<Array<TechnitionData>>) => response.data)
}
export {getServices, getAllServices, filterService, getAllStores, getAllEmployees, filterEmployees}
