import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {StoresQueryResponse, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_ALL_STORES = `${API_URL}/store`

const createUser = (user: User, token: string | undefined): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/register`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      user,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data.data)
  // .then((response: Response<User>) => response.data)
}
const getStores = (token: string | undefined): Promise<StoresQueryResponse | undefined> => {
  return axios
    .get(`${GET_ALL_STORES}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<StoresQueryResponse>) => d.data)
}
export {createUser, getStores}
