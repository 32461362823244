/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const TranscationInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {user?.product?.productPicture && user?.product?.productPicture.length > 0 ? (
            <div className='symbol-label'>
              <img
                src={user?.product?.productPicture[0]?.Location}
                alt={user?.product?.productCode}
                className='w-100'
              />
            </div>
          ) : (
            <div className='symbol-label'>
              <img
                src={
                  'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                }
                className='w-100'
              />
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {user?.product?.productName}
        </a>
        <span>{user?.product?.productCode}</span>
      </div>
    </div>
  )
}

export {TranscationInfoCell}
