import moment from 'moment'
import {FC} from 'react'

type Props = {
  last_login?: string
}

const StoreCreatedDate: FC<Props> = ({last_login}) => (
  <div className='fw-bolder'>{moment(last_login).format('MM/DD/YYYY')}</div>
)

export default StoreCreatedDate
