import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
import {Toaster} from 'react-hot-toast'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Toaster position='top-right' reverseOrder={false} />
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
