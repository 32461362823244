import moment from 'moment'
import {FC} from 'react'

type Props = {
  createdAt?: string
  updatedAt?: string
}

const ServiceCreatedAt: FC<Props> = ({createdAt, updatedAt}) => (
  <>
    {createdAt ? (
      <div className='d-flex flex-column'>
        <p className=''>{moment(createdAt).format('DD-MM-YY')}/</p>
        <p className=''>{moment(updatedAt).format('DD-MM-YY')}</p>
      </div>
    ) : (
      <div className=''>Not select</div>
    )}
  </>
)

export {ServiceCreatedAt}
