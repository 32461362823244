import {FC} from 'react'

type Props = {
  natureOfComplaint?: string
}

const EmployeLastLoginCell: FC<Props> = ({natureOfComplaint}) => (
  <div className='badge badge-light fw-bolder'>{natureOfComplaint}</div>
)

export {EmployeLastLoginCell}
