/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {useGetEmployeeById} from './core/QueryResponseProvider'
import {User} from './core/_models'

export function Overview() {
  const {id} = useParams()
  const {data} = useGetEmployeeById(id)
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Employee Details</h3>
          </div>

          <Link
            to={`/apps/employe-details/${id}/settings`}
            className='btn btn-primary align-self-center'
          >
            Edit Employee
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{data?.fullName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Phone</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{data?.phone}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Email Id
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{data?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Branch
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {data?.branch ? data?.branch?.storeName : 'data?.branch?.storeName'}
              </span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Designation</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{data?.designation}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
