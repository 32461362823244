import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CreateNote, GetNotes, Service, UpdateTechnition, UsersQueryResponse} from './_models'
import {User as TechnitionData} from '../../../apps/employee-management/employes-list/core/_models'
import {service} from 'aws-sdk/clients/health'
const API_URL = process.env.REACT_APP_THEME_API_URL
const UPDATE_SERVICE_URL = `${API_URL}/service`
const GET_SERVICE_URL = `${API_URL}/service`
const GET_EMPLOYEE_URL = `${API_URL}/employee`
const ADD_NOTE_URL = `${API_URL}/note`

const getServiceById = (id: string, token: string | undefined): Promise<Service | undefined> => {
  return axios
    .get(`${GET_SERVICE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}
const getAllEmployees = (token: string | undefined): Promise<Array<TechnitionData> | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<TechnitionData>>>) => response.data)
    .then((response: Response<Array<TechnitionData>>) => response.data)
}
const updateServiceEmp = (
  token: string | undefined,
  id: string | undefined,
  data: UpdateTechnition | undefined
): Promise<Service | undefined> => {
  return axios
    .put(`${UPDATE_SERVICE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}
const updateService = (
  token: string | undefined,
  id: string | undefined,
  data: Service | undefined
): Promise<Service | undefined> => {
  return axios
    .put(`${UPDATE_SERVICE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}
const updateServiceNote = (
  token: string | undefined,
  id: string | undefined,
  data: Service | undefined
): Promise<Service | undefined> => {
  return axios
    .put(`${UPDATE_SERVICE_URL}/notes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}
const createNote = (
  token: string | undefined,
  data: CreateNote | undefined
): Promise<GetNotes | undefined> => {
  return axios
    .post(`${ADD_NOTE_URL}/create`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<GetNotes>>) => response.data)
    .then((response: Response<GetNotes>) => response.data)
}
const getNote = (token: string | undefined): Promise<Array<GetNotes> | undefined> => {
  return axios
    .get(`${ADD_NOTE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<GetNotes>>>) => response.data)
    .then((response: Response<Array<GetNotes>>) => response.data)
}

export {
  getServiceById,
  updateServiceEmp,
  getAllEmployees,
  updateService,
  createNote,
  getNote,
  updateServiceNote,
}
