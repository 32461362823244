// @ts-nocheck
import {Column} from 'react-table'
import {ProductInfoCell} from './ProductInfoCell'
import {ProductLastLoginCell} from './ProductLastLoginCell'
import {ProductTwoStepsCell} from './ProductTwoStepsCell'
import {ProductActionsCell} from './ProductActionsCell'
import {ProductSelectionCell} from './ProductSelectionCell'
import {ProductCustomHeader} from './ProductCustomHeader'
import {ProductSelectionHeader} from './ProductSelectionHeader'
import {Product} from '../../core/_models'
import {ProductStatusCell} from './ProductStatusCell'
import {ProductSku} from './ProductSku'
import {ProductCode} from './ProductCode'

const ProductsColumns: ReadonlyArray<Column<Product>> = [
  // {
  //   Header: (props) => <ProductSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ProductSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Product name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ProductInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Tax' className='min-w-125px' />
    ),

    id: 'tax',
    Cell: ({...props}) => <ProductSku last_login={props.data[props.row.index].productTax} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Code/sku' className='min-w-125px' />
    ),

    id: 'code',
    Cell: ({...props}) => (
      <ProductCode
        code={props.data[props.row.index].productCode}
        sku={props.data[props.row.index].productSku}
      />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Price' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({...props}) => (
      <ProductLastLoginCell last_login={props.data[props.row.index].productPrice} />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title='Product created date'
        className='min-w-125px'
      />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ProductTwoStepsCell two_steps={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Status' className='text-end min-w-100px' />
    ),
    id: 'status',
    Cell: ({...props}) => <ProductStatusCell id={props.data[props.row.index].is_deactivated} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {ProductsColumns}
