import {useListView} from '../../core/ListViewProvider'
import { EmployesListToolbar } from './EmployeListToolbar'
import { EmployesListGrouping } from './EmployesListGrouping'
import { EmployesListSearchComponent } from './EmployesListSearchComponent'



const EmployesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EmployesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <EmployesListGrouping /> : <EmployesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {EmployesListHeader}
