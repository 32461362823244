/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {Link, useLocation} from 'react-router-dom'

type Props = {
  user: User
}

const EmployeInfoCell: FC<Props> = ({user}) => {
  const location = useLocation()
  const screen = location.pathname.split('/')[3]

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {user.profilePicture ? (
            <div className='symbol-label'>
              <img src={user.profilePicture} alt={user.fullName} className='w-100' />
            </div>
          ) : (
            <div className='symbol-label'>
              <img
                src='https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                alt={user.fullName}
                className='w-100'
              />
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <Link
          to={`/apps/employe-details/${user._id}/overview`}
          className='text-gray-800 text-hover-primary mb-1'
        >
          {user.fullName}
        </Link>
        <span>{user.email}</span>
      </div>
    </div>
  )
}

export {EmployeInfoCell}
