/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'

import {ID, KTSVG, QUERIES} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'

type Props = {
  repairType?: string
}

const EmployeStatusCell: FC<Props> = ({repairType}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const location = useLocation()
  const screen = location.pathname.split('/')[3]
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      <div className='fw-bolder'>{repairType}</div>
    </>
  )
}

export {EmployeStatusCell}
