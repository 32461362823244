// @ts-nocheck
import {Column} from 'react-table'
import {EmployeInfoCell} from './EmployeInfoCell'
import {EmployeLastLoginCell} from './EmployeLastLoginCell'
import {EmployeTwoStepsCell} from './EmployeTwoStepsCell'
import {EmployeActionsCell} from './EmployeActionsCell'
import {EmployeSelectionCell} from './EmployeSelectionCell'
import {EmployeCustomHeader} from './EmployeCustomHeader'
import {EmployeSelectionHeader} from './EmployeSelectionHeader'
import {Service} from '../../core/_models'
import {EmployeStatusCell} from './EmployeStatusCell'
import {TTKPLNumber} from './TTKPLNumber'

const usersColumns: ReadonlyArray<Column<Service>> = [
  {
    Header: (props) => <EmployeSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <EmployeSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Customer name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <EmployeInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='TTKPL Number' className='min-w-125px' />
    ),
    id: 'ttkplnumber',
    Cell: ({...props}) => (
      <TTKPLNumber ttkplNumber={props.data[props.row.index].TTKPLComplaintsNumber} />
    ),
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Nature of Comaplint' className='min-w-125px' />
    ),
    id: 'nature_of_complaint',
    Cell: ({...props}) => (
      <EmployeLastLoginCell natureOfComplaint={props.data[props.row.index].natureOfComplaint} />
    ),
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Reapir Class' className='min-w-125px' />
    ),
    id: 'repair_class',
    Cell: ({...props}) => (
      <EmployeTwoStepsCell repairClass={props.data[props.row.index].repairClass} />
    ),
  },
  {
    Header: (props) => (
      <EmployeCustomHeader
        tableProps={props}
        title='Repair type'
        className='text-start min-w-100px'
      />
    ),
    id: 'reapair_type',
    Cell: ({...props}) => <EmployeStatusCell repairType={props.data[props.row.index].repairType} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Created at' className='text-end min-w-100px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <EmployeActionsCell createdAt={props.data[props.row.index].createdAt} />,
  },
]

export {usersColumns}
