import React, {useEffect, useState} from 'react'
import {QUERIES, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useGetProductById, useUpdateProductId} from '../../core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {uploadImagesToS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {IMageUpload} from '../../../../add-product/components/settings/SettingsModel'

const profileDetailsSchema = Yup.object().shape({
  productName: Yup.string().required('productName is required'),
  // productId: Yup.string().required('productId is required'),
  // productSerialNumber: Yup.string().required('productSerialNumber is required'),
  // productModalNumber: Yup.string().required('productModalNumber is required'),
  // productSku: Yup.string().required('productSku is required'),
  productDescription: Yup.string().required('productDescription is required'),
  productPrice: Yup.string().required('productPrice is required'),
  productCode: Yup.string().required('product Code is required'),
  productHsnCode: Yup.string().required('product Hsn Code is required'),
  productTax: Yup.string().required('product Tax is required'),
})
// productCode?: string
// productHsnCode?: string
// productTax?: string
const ProfileDetails: React.FC = () => {
  const {id} = useParams()
  const {data: response, refetch} = useGetProductById(id)
  const queryClient = useQueryClient()
  const [data, setData] = useState<IProfileDetails>(response ? response : initialValues)
  const {mutate, isSuccess} = useUpdateProductId(id)
  const [loading, setLoading] = useState(false)
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState<IMageUpload[]>(
    data?.productPicture && data?.productPicture?.length > 0 ? data.productPicture : []
  )
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('multiple', '')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        console.log(selectedFile)
        try {
          const data = await uploadImagesToS3(selectedFile)
          console.log(data)
          if (data) {
            let newData = data.map((da) => {
              return {Location: da.Location}
            })
            if (profilePicture.length > 0) {
              profilePicture.push.apply(profilePicture, newData)
            } else {
              setProfilePicture(newData)
            }
          }
          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          console.log(error)

          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  useEffect(() => {
    setData(response ? response : initialValues)
  }, [response])
  const formik = useFormik<IProfileDetails>({
    initialValues: data,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log(values)
console.log(profilePicture);

      try {
        if (profilePicture.length > 0) {
          values.productPicture = profilePicture
        }
        await mutate(values)

        setLoading(false)
        return toast.success('Product updated successfully')
      } catch (error) {
        setLoading(false)
        return toast.error('Something went wrong')
      }
    },
  })
  const deleteImage = (id: string | undefined) => {
    console.log('delete the image', id)

    let newPicture = profilePicture.filter((data) => data._id !== id)
    console.log(newPicture)

    setProfilePicture(newPicture)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>

              <div className='col-lg-8'>
                {profilePicture.length > 0 ? (
                  <div className='d-flex'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                    >
                      {profilePicture.map((image) => {
                        return (
                          <div
                            key={image.Location}
                            className='image-input image-input-outline'
                            data-kt-image-input='true'
                            style={{
                              backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                            }}
                          >
                            <div
                              className='image-input-wrapper w-125px h-125px'
                              style={{
                                backgroundImage: `url(${image.Location})`,
                              }}
                            >
                              <i
                                onClick={() => deleteImage(image?._id)}
                                className='bi bi-trash cursor-pointer'
                              ></i>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{
                          backgroundImage: `url(https://static.vecteezy.com/system/resources/previews/004/968/473/original/upload-or-add-a-picture-jpg-file-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector.jpg)`,
                        }}
                        onClick={() => handleGetTheImageFromLocal()}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product name'
                  {...formik.getFieldProps('productName')}
                />
                {formik.touched.productName && formik.errors.productName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productName}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product code / SKU</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product code'
                  {...formik.getFieldProps('productCode')}
                />
                {formik.touched.productCode && formik.errors.productCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product HSN Code</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product HSN  Code'
                  {...formik.getFieldProps('productHsnCode')}
                />
                {formik.touched.productHsnCode && formik.errors.productHsnCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productHsnCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Tax</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('productTax')}
                >
                  <option value=''>--select tax--</option>
                  <option value='5'>5</option>
                  <option value='12'>12</option>
                  <option value='18'>18</option>
                  <option value='28'>28</option>
                </select>
                {formik.touched.productTax && formik.errors.productTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productTax}</div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product ID'
                  {...formik.getFieldProps('productId')}
                />
                {formik.touched.productId && formik.errors.productId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productId}</div>
                  </div>
                )}
              </div>
            </div> */}

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product SKU</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product SKU'
                  {...formik.getFieldProps('productSku')}
                />
                {formik.touched.productSku && formik.errors.productSku && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productSku}</div>
                  </div>
                )}
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product Description'
                  {...formik.getFieldProps('productDescription')}
                />
                {formik.touched.productDescription && formik.errors.productDescription && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productDescription}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Price</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Price'
                  {...formik.getFieldProps('productPrice')}
                />
                {formik.touched.productPrice && formik.errors.productPrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.productPrice}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
