import {useAuth} from '../../../../../auth'
import {useListView} from '../../core/ListViewProvider'
import {StoresListToolbar} from './StoreListToolbar'
import {StoresListGrouping} from './StoresListGrouping'
import {StoresListSearchComponent} from './StoresListSearchComponent'

const StoresListHeader = () => {
  const {selected} = useListView()
  const {currentUser} = useAuth()

  return (
    <div className='card-header border-0 pt-6'>
      <StoresListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <StoresListGrouping />
        ) : (
          currentUser && currentUser.user.is_admin && <StoresListToolbar />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {StoresListHeader}
