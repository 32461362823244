import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useGetServiceById, useUpdateServiceId} from '../../core/QueryResponseProvider'
import {useNavigate, useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  uploadImageOnS3,
  uploadImagesToS3,
} from '../../../../../../_metronic/helpers/uitils/functions'
import {IMageUpload} from '../../../../add-product/components/settings/SettingsModel'
import {useAuth} from '../../../../auth'

const profileDetailsSchema = Yup.object().shape({
  estimatedPricing: Yup.string(),
  finalPricing: Yup.string(),
  paymentMethod: Yup.string(),
})

const ProfileDetails: React.FC = () => {
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const navigate = useNavigate()
  const [profilePicture, setProfilePicture] = useState<IMageUpload[]>([])
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const {id} = useParams()
  const {mutate} = useUpdateServiceId(id)
  const {data: pricing, isFetching, refetch} = useGetServiceById(id)
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('multiple', '')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        console.log(selectedFile)
        try {
          const data = await uploadImagesToS3(selectedFile)
          console.log(data)
          if (data) {
            let newData = data.map((da) => {
              return {Location: da.Location}
            })
            if (pricing?.paymentVerifyImage && pricing?.paymentVerifyImage?.length > 0) {
              for (let index = 0; index < pricing?.paymentVerifyImage?.length; index++) {
                const element = pricing?.paymentVerifyImage[index]
                newData.push(element)
              }
              pricing.paymentVerifyImage = newData
            }
            setProfilePicture(newData)
          }
          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          console.log(error)

          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  const formik = useFormik<IProfileDetails>({
    initialValues: pricing ? pricing : initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log(values)
      if (pricing?.status !== 'Approved by admin' && pricing?.status !== 'Completed by employee') {
        try {
          if (profilePicture.length > 0) {
            const mutateData = {...values, paymentVerifyImage: profilePicture}
            console.log('mutateData', mutateData)

            await mutate(mutateData)
            setLoading(false)

            navigate(-1)
            toast.success('Updated successfully')
          } else {
            await mutate(values)
            setLoading(false)
            navigate(-1)
            toast.success('Updated successfully')
          }
        } catch (error) {
          toast.error('Something went wrong')
          setLoading(false)
        }
      }
      if (pricing?.status === 'Completed by employee' && currentUser?.user.is_admin) {
        try {
          if (profilePicture.length > 0) {
            const mutateData = {...values, paymentVerifyImage: profilePicture}
            console.log('mutateData', mutateData)

            await mutate(mutateData)
            setLoading(false)

            navigate(-1)
            toast.success('Updated successfully')
          } else {
            await mutate(values)
            setLoading(false)
            navigate(-1)
            toast.success('Updated successfully')
          }
        } catch (error) {
          toast.error('Something went wrong')
          setLoading(false)
        }
      }
      if (pricing?.status === 'Approved by admin') {
        setLoading(false)
        toast.error('no one can update this')
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Estimated pricing</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Estimated pricing
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Estimated pricing'
                  {...formik.getFieldProps('estimatedPricing')}
                />
                {formik.touched.estimatedPricing && formik.errors.estimatedPricing && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.estimatedPricing}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Payment method</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('paymentMethod')}
                >
                  <option value=''>--select method--</option>
                  <option value='cash'>CASH</option>
                  <option value='card'>CARD</option>
                  <option value='upi'>UPI</option>
                </select>
                {formik.touched.paymentMethod && formik.errors.paymentMethod && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.paymentMethod}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'> Final pricing</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' Final pricing'
                  {...formik.getFieldProps('finalPricing')}
                />
                {formik.touched.finalPricing && formik.errors.finalPricing && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.finalPricing}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Payment proof</label>
              <div className='col-lg-8'>
                {pricing?.paymentVerifyImage && pricing?.paymentVerifyImage.length > 0
                  ? pricing?.paymentVerifyImage.map((image) => {
                      return (
                        <div
                          className='image-input image-input-outline'
                          data-kt-image-input='true'
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                          }}
                        >
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url(${image.Location})`,
                            }}
                          ></div>
                        </div>
                      )
                    })
                  : profilePicture.map((image) => {
                      return (
                        <div
                          className='image-input image-input-outline'
                          data-kt-image-input='true'
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                          }}
                        >
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url(${image.Location})`,
                            }}
                          ></div>
                        </div>
                      )
                    })}
                <div className='row mb-6'>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{
                          backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
