/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
type Props = {
  user: User
}

const date = Date.now()
const ServiceInfoCell: FC<Props> = ({user}) => {
  // const users = useQueryResponseData()
  console.log(user.product?.productPicture)
  // function generatePDF(users: User[]) {
  //   const doc = new jsPDF()

  //   const tableColumn = Object.keys(users[0]) // Extract column headers from the first object in the JSON data
  //   const tableRows = users.map((obj) => Object.values(obj)) // Extract values from each object in the JSON data

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //   })

  //   doc.save('output.pdf')
  // }

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {user.product?.productPicture && user.product?.productPicture?.length > 0 ? (
            <div className='symbol-label'>
              <img
                src={user.product?.productPicture[0].Location}
                alt={user.product.productCode}
                className='w-100'
              />
            </div>
          ) : (
            <div className='symbol-label'>
              <img
                src={
                  'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                }
                className='w-100'
              />
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {user.product?.productName}
        </a>
        <span>{user.product?.productCode}</span>
      </div>
    </div>
  )
}

export {ServiceInfoCell}
