import {useMutation, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getUserById, updateUser} from './_request'

import {useEffect} from 'react'
import {User} from './_models'
import {useAuth} from '../../../auth'

export const useGetUserById = (id: any) => {
  const {auth} = useAuth()
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.USER_LIST_BY_ID}-${id}`,
    () => {
      return getUserById(id, auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return {isFetching, refetch, data}
}
// const mutation = useMutation((newTodo) => updateUser())
export const useUpdateEmployeeById = (id: any) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError} = useMutation(
    (newUser: User) => updateUser(newUser, auth?.access_token),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.USER_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError}
}
