import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {
  IProductDetails,
  IUserDetails,
  ProductDetailsInitValues as initialProductValues,
  userDetailsInitValues as initialUserValues,
  serviceDetailsInitValues as initialServiceValues,
  IServiceDetails,
} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {User, Product} from '../../core/_models'
import {useUpdateProductId} from '../../core/QueryResponseProvider'

import toast from 'react-hot-toast'
import {uploadImagesToS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {useAuth} from '../../../../auth'
import {
  createProduct,
  createService,
  searchProduct,
  searchUser,
  updateUserComplaint,
} from '../../core/_request'
import {useNavigate} from 'react-router-dom'
import {Store} from '../../../../add-user/components/core/_models'
import {createUser, getStores} from '../../../../add-user/components/core/_request'

const ProductDetailsSchema = Yup.object().shape({
  productName: Yup.string().required('productName is required'),
  // productId: Yup.string().required('productId is required'),
  productCode: Yup.string(),
  productTax: Yup.string(),
  productDescription: Yup.string(),
  productPrice: Yup.string(),
})
const UserDetailsSchema = Yup.object().shape({
  fullName: Yup.string(),
  phone: Yup.string(),
  additionalPhoneNumber: Yup.string(),
  pincode: Yup.string(),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  locality: Yup.string(),
  landmark: Yup.string(),
  email: Yup.string(),
  nearByBranch: Yup.string(),
  customerType: Yup.string(),
  profilePicture: Yup.string(),
})

const ServiceDetailsSchema = Yup.object().shape({
  natureOfComplain: Yup.string(),
  repairType: Yup.string(),
  productCondition: Yup.string().nullable().default(undefined),
  repairClass: Yup.string(),
  specialInstructionByCustomer: Yup.string().nullable().default(undefined),
  // startDate: Yup.string().nullable().default(undefined),
  // endDate: Yup.string().nullable().default(undefined),
  natureOfComplaints: Yup.string().nullable().default(undefined),
  TTKPLComplaintsNumber: Yup.string().nullable().default(undefined),
  repairTypeOther: Yup.string(),
  store: Yup.string(),
})
interface Images {
  Location: string
}
const ProfileDetails: React.FC = () => {
  const [stores, setStores] = useState<Store[]>() // let {user, product} = contextValues
  const [searchProductCode, setSerachProductCode] = useState<string | undefined>()
  const [searchUserCode, setSerachUserCode] = useState<string | undefined>()
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const {auth, currentUser} = useAuth()
  const navigate = useNavigate()
  // handle the new upload images
  const [user, setUser] = useState<User | null>()
  const [product, setProduct] = useState<Product | null>()
  const [selectedUserImage, setSelectedUserImage] = useState(user ? user.profilePicture : null)
  const [warrantyCardImages, setWarrantyCardImages] = useState<Images[]>([])
  const [selectedProductImage, setSelectedProductImage] = useState(
    product ? product.productPicture : []
  )
  useEffect(() => {
    const fetchStores = async () => {
      const data = await getStores(auth?.access_token)
      console.log(data?.data)

      setStores(data?.data)
    }
    fetchStores()
  }, [])

  const [productReviewOther, setProductReviewOther] = useState(false)
  useEffect(() => {
    try {
      if (user) {
        setSelectedUserImage(user ? user.profilePicture : '')
      }
      if (product) {
        setSelectedProductImage(product ? product.productPicture : [])
      }
    } catch (error) {}
  }, [user, product])

  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('multiple', '')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          const data = await uploadImagesToS3(selectedFile)
          console.log(data)
          if (data) {
            let newData = data.map((da) => {
              return {Location: da.Location}
            })

            // setUploadImages()
            if (warrantyCardImages && warrantyCardImages.length > 0) {
              for (let index = 0; index < warrantyCardImages.length; index++) {
                const element = warrantyCardImages[index]
                newData.push(element)
              }
            }
            // setSelectedProductImage(newData)
            setWarrantyCardImages(newData)
            console.log('this is the data new image', newData)
          }
          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          console.log(error)

          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }

  const [loading, setLoading] = useState(false)
  const formikProduct = useFormik<IProductDetails>({
    initialValues: product ? product : initialProductValues,
    enableReinitialize: true,
    validationSchema: ProductDetailsSchema,
    onSubmit: async (values) => {
      console.log('main click ho raha hoon')
      try {
        if (selectedProductImage && selectedProductImage.length > 0) {
          let productData = {
            productName: values.productName,
            productCode: searchProductCode,
            productId: searchProductCode,
            productPicture: selectedProductImage,
          }
          const newProduct = await createProduct(productData, auth?.access_token)
          console.log('this is the new product with image', newProduct)
          if (newProduct?._id) {
            toast.success('Product added successfully')
          }
          setProduct(newProduct)
        } else {
          let productData = {
            productName: values.productName,
            productCode: searchProductCode,
            productId: searchProductCode,
          }
          const newProduct = await createProduct(productData, auth?.access_token)
          
          setProduct(newProduct)
          if (newProduct?._id) {
            toast.success('Product added successfully')
          }
          console.log('this is the new product without image', newProduct?._id)
        }
      } catch (error) {
        toast.error('Something went wrong with the product')
        console.log('this is the error', error)
      }
      console.log('this is the new product values', values)
    },
  })
  const formikUser = useFormik<IUserDetails>({
    initialValues: user ? user : initialUserValues,
    enableReinitialize: true,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values) => {
      console.log(values)
      try {
        setLoading(true)
        values.phone = searchUserCode
        const mutateData = await createUser(values, auth?.access_token)

        if (mutateData?.message) {
          let message = mutateData.message
          toast.error(message)
        } else {
          setUser(mutateData)
          setLoading(false)
        }
        setLoading(false)
      } catch (error) {
        console.log(error)

        setLoading(false)
        toast.error('phone number is duplicate')
      }
    },
  })

  const formik = useFormik<IServiceDetails>({
    initialValues: initialServiceValues,
    validationSchema: ServiceDetailsSchema,
    onSubmit: async (values) => {
      console.log('---->', product, user)

      if (product && user) {
        const data = {
          product: product._id,
          user: user._id,
          natureOfComplaint: values.natureOfComplain,
          repairType: values.repairTypeOther ? values.repairTypeOther : values?.repairType,
          productCondition: values.productCondition,
          repairClass: values.repairClass,
          specialInstructionByCustomer: values.specialInstructionByCustomer,
          natureOfComplaints: values.natureOfComplaints,
          TTKPLComplaintsNumber: values.TTKPLComplaintsNumber,
          startDate: values.startDate,
          endDate: values.endDate,
          warrantyCardImages: warrantyCardImages,
          bookedBy: currentUser?.user._id,
          store: values.store ? values.store : currentUser?.user?.branch,
        }
        console.log(data, values.TTKPLComplaintsNumber)

        try {
          const newServicedata = await createService(data, auth?.access_token)
          console.log('this is the newly add service', newServicedata)

          if (newServicedata && newServicedata._id) {
            await updateUserComplaint(
              {_id: user._id, complaints: newServicedata._id},
              auth?.access_token
            )
          }

          toast.success('service create successfully')

          navigate('/apps/service-management/services')
        } catch (error: any) {
          console.log('this is the error', error)
          // if (error.err.keyPattern.TTKPLComplaintsNumber) {
          toast.error('Duplicate value occur ' + error)
          // } else {
          // toast.error('Something went wrong')
          // }
        }
      }
    },
  })
  const handleSearchProduct = async () => {
    if (searchProductCode) {
      try {
        const data = await searchProduct(searchProductCode, auth?.access_token)
        if (data && data?.length > 0) {
          setProduct(data[0])
        } else {
          setProduct(null)
          toast.error('no product found')
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    } else {
      return
    }
  }
  const deleteSelectedImage = (id: string) => {
    console.log('delete image ', id)

    console.log('this is the new images', selectedProductImage)
    let newImage = warrantyCardImages?.filter((data) => data.Location !== id)

    setWarrantyCardImages(newImage)
  }
  const handleSearchUser = async () => {
    if (searchUserCode) {
      try {
        const data = await searchUser(searchUserCode, auth?.access_token)
        console.log('this is the search user data ', searchUserCode, data)
        if (data && data?.length > 0) {
          setUser(data[0])
        } else {
          toast.error('no user found')
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    } else {
      return
    }
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Product details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formikProduct.handleSubmit} noValidate className='form'>
            {/* <div className='text-end border'>
              <ProducttListFilter />
            </div> */}
            <div className='card-body border-top p-9'>
              <div className='row d-flex align-items-center mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product code / SKU</span>
                </label>

                <div className='col-lg-6 d-flex align-items-center flex-column fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Product code / SKU'
                    onChange={(e) => setSerachProductCode(e.target.value)}
                    value={searchProductCode}
                  />
                  {formikProduct.touched.productCode && formikProduct.errors.productCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikProduct.errors.productCode}</div>
                    </div>
                  )}
                </div>
                <div onClick={() => handleSearchProduct()} className='col-lg-2 btn btn-primary'>
                  search
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Product image</label>
                <div className='col-lg-8'>
                  <div className='d-flex justify-content-start flex-wrap'>
                    {warrantyCardImages && warrantyCardImages.length > 0 ? (
                      <div className='d-flex justify-content-evenly flex-wrap'>
                        {warrantyCardImages.map((image: any) => {
                          return (
                            <div className='row mb-6'>
                              <div key={image._id} className='col-lg-8'>
                                <div
                                  className='image-input image-input-outline'
                                  data-kt-image-input='true'
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      '/media/avatars/blank.png'
                                    )})`,
                                  }}
                                >
                                  <div
                                    className='image-input-wrapper w-150px h-150px'
                                    style={{
                                      backgroundImage: `url(${image.Location})`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <div className='row mb-6'>
                        <div className='col-lg-8'>
                          <div
                            className='image-input image-input-outline'
                            data-kt-image-input='true'
                            style={{
                              backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                            }}
                          >
                            <div
                              className='image-input-wrapper w-125px h-125px'
                              onClick={() => handleGetTheImageFromLocal()}
                              style={{
                                backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Product name'
                    {...formikProduct.getFieldProps('productName')}
                  />
                  {formikProduct.touched.productName && formikProduct.errors.productName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikProduct.errors.productName}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product Tax</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formikProduct.getFieldProps('productTax')}
                  >
                    <option value=''>--select tax--</option>
                    <option value='5'>5</option>
                    <option value='12'>12</option>
                    <option value='18'>18</option>
                    <option value='28'>28</option>
                  </select>
                  {formikProduct.touched.productTax && formikProduct.errors.productTax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikProduct.errors.productTax}</div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product Description</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Product Description'
                    {...formikProduct.getFieldProps('productDescription')}
                  />
                  {formikProduct.touched.productDescription &&
                    formikProduct.errors.productDescription && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formikProduct.errors.productDescription}
                        </div>
                      </div>
                    )}
                </div>
              </div> */}
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Price</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Price'
                    {...formikProduct.getFieldProps('productPrice')}
                  />
                  {formikProduct.touched.productPrice && formikProduct.errors.productPrice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikProduct.errors.productPrice}</div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            {!product && (
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary'>
                  {!loading && 'Add Product'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Customer details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formikUser.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Phone</span>
                </label>

                <div className='col-lg-6 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone'
                    onChange={(e) => setSerachUserCode(e.target.value)}
                    value={searchUserCode}
                  />
                  {formikUser.touched.phone && formikUser.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.phone}</div>
                    </div>
                  )}
                </div>
                <div onClick={() => handleSearchUser()} className='col-lg-2 btn btn-primary'>
                  search
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Image</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${
                          selectedUserImage
                            ? selectedUserImage
                            : toAbsoluteUrl('/media/avatars/blank.png')
                        })`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        {...formikUser.getFieldProps('fullName')}
                      />
                      {formikUser.touched.fullName && formikUser.errors.fullName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formikUser.errors.fullName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Additional phone number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Additional phone number'
                    {...formikUser.getFieldProps('additionalPhoneNumber')}
                  />
                  {formikUser.touched.additionalPhoneNumber &&
                    formikUser.errors.additionalPhoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formikUser.errors.additionalPhoneNumber}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Pincode</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Pincode'
                    {...formikUser.getFieldProps('pincode')}
                  />
                  {formikUser.touched.pincode && formikUser.errors.pincode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.pincode}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Country</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Country'
                    {...formikUser.getFieldProps('country')}
                  />
                  {formikUser.touched.country && formikUser.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.country}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>State</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='State'
                    {...formikUser.getFieldProps('state')}
                  />
                  {formikUser.touched.state && formikUser.errors.state && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.state}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>City</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='City'
                    {...formikUser.getFieldProps('city')}
                  />
                  {formikUser.touched.city && formikUser.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.city}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Locality</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Locality'
                    {...formikUser.getFieldProps('locality')}
                  />
                  {formikUser.touched.locality && formikUser.errors.locality && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.locality}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Landmark</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Landmark'
                    {...formikUser.getFieldProps('landmark')}
                  />
                  {formikUser.touched.landmark && formikUser.errors.landmark && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.landmark}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Email id</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email id'
                    {...formikUser.getFieldProps('email')}
                  />
                  {formikUser.touched.email && formikUser.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Near by Branch</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formikUser.getFieldProps('nearByBranch')}
                  >
                    <option value={''}>---select-option---</option>
                    {stores &&
                      stores?.map((store) => {
                        return (
                          <option key={store._id} value={store._id}>
                            {`${store.storeName} - ${store.storeState}`}
                          </option>
                        )
                      })}
                  </select>
                  {formikUser.touched.nearByBranch && formikUser.errors.nearByBranch && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.nearByBranch}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Customer Type</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formikUser.getFieldProps('customerType')}
                  >
                    <option value={''}>---select-option---</option>
                    <option value='Vip'>Vip</option>
                    <option value='normal'>Normal</option>
                  </select>
                  {formikUser.touched.customerType && formikUser.errors.customerType && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikUser.errors.customerType}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Add User'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_product_details'
          aria-expanded='false'
          aria-controls='kt_account_product_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Product review</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>TTKPL Complaint Number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='TTKPL Complaint Number'
                    {...formik.getFieldProps('TTKPLComplaintsNumber')}
                  />
                  {formik.touched.TTKPLComplaintsNumber && formik.errors.TTKPLComplaintsNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.TTKPLComplaintsNumber}</div>
                    </div>
                  )}
                </div>
              </div>

              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nature of Complaint</span>
                </label>

                <div className='d-flex gap-5 fv-row'>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Installation'
                      name='natureOfComplain'
                      id='reverseCheck1'
                      // checked={formik.values.natureOfComplain.installation}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Installation
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Warranty'
                      name='natureOfComplain'
                      id='reverseCheck1'
                      // checked={formik.values.natureOfComplain.warranty}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Warranty
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Out of warranty'
                      id='reverseCheck1'
                      name='natureOfComplain'
                      // checked={formik.values.natureOfComplain.outOfWarranty}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Out of warranty
                    </label>
                  </div>
                </div>
              </div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Repair type</span>
                </label>

                <div>
                  <div className='d-flex gap-5 fv-row'>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Part replacement'
                        name='repairType'
                        id='reverseCheck13'
                        // checked={formik.values.repairType.partReplacement}
                        onChange={formik.handleChange}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Part replacement
                      </label>
                    </div>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Adjustment'
                        id='reverseCheck13'
                        name='repairType'
                        // checked={formik.values.repairType.adjustment}
                        onChange={formik.handleChange}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Adjustment
                      </label>
                    </div>

                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Customer education'
                        id='reverseCheck13'
                        name='repairType'
                        // checked={formik.values.repairType.customerEducation}
                        onChange={formik.handleChange}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Customer education
                      </label>
                    </div>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        name='repairType'
                        type='radio'
                        value='others'
                        id='reverseCheck13'
                        onClick={() => setProductReviewOther((state) => !state)}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        others
                      </label>
                    </div>
                  </div>
                  {productReviewOther && (
                    <div className='col-lg-8 mt-5 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='others'
                        {...formik.getFieldProps('repairTypeOther')}
                      />
                      {formik.touched.repairTypeOther && formik?.errors?.repairTypeOther && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.repairTypeOther}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product condition</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Product condition'
                    {...formik.getFieldProps('productCondition')}
                  />
                  {formik.touched.productCondition && formik.errors.productCondition && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.productCondition}</div>
                    </div>
                  )}
                </div>
              </div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Repair class</span>
                </label>

                <div className='d-flex gap-5 fv-row'>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='Service center workshop'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Service center workshop
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='Filed'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Filed
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='Dealer'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Dealer
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='Showrroom'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Showrroom
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='PSK'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      PSK
                    </label>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Special instructions by the customer</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Special instructions by the customer'
                    {...formik.getFieldProps('specialInstructionByCustomer')}
                  />
                  {formik.touched.specialInstructionByCustomer &&
                    formik.errors.specialInstructionByCustomer && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.specialInstructionByCustomer}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nature of complaint</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nature of complaint'
                    {...formik.getFieldProps('natureOfComplaints')}
                  />
                  {formik.touched.natureOfComplaints && formik.errors.natureOfComplaints && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.natureOfComplaints}</div>
                    </div>
                  )}
                </div>
              </div>
              {currentUser && currentUser.user.is_admin && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Store</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg fw-bold'
                      {...formik.getFieldProps('store')}
                    >
                      <option value={''}>---select-option---</option>
                      {stores &&
                        stores?.map((store) => {
                          return (
                            <option key={store._id} value={store._id}>
                              {`${store.storeName} - ${store.storeState}`}
                            </option>
                          )
                        })}
                    </select>
                    {formik.touched.store && formik.errors.store && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.store}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Start Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Start Date'
                    {...formik.getFieldProps('startDate')}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.startDate}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>End Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='End Date'
                    {...formik.getFieldProps('endDate')}
                  />
                  {formik.touched.endDate && formik.errors.endDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.endDate}</div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Create a complaint'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </form>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_product_details'
          aria-expanded='false'
          aria-controls='kt_account_product_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Product and warranty card images</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='d-flex justify-content-evenly flex-wrap'>
              {warrantyCardImages && warrantyCardImages.length > 0 ? (
                <div className='d-flex justify-content-evenly flex-wrap'>
                  {warrantyCardImages.map((image: any) => {
                    return (
                      <div className='row mb-6'>
                        <div key={image.Location} className='col-lg-8'>
                          <div
                            className='image-input image-input-outline'
                            data-kt-image-input='true'
                            style={{
                              backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                            }}
                          >
                            <div
                              className='image-input-wrapper w-300px h-300px position-releative'
                              style={{
                                backgroundImage: `url(${image.Location})`,
                              }}
                            >
                              <i
                                onClick={() => deleteSelectedImage(image.Location)}
                                className='bi bi-trash-fill text-danger text-end btn'
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className='row mb-6'>
                    <div className='col-lg-8'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                        onClick={() => handleGetTheImageFromLocal()}
                      >
                        <div
                          className='image-input-wrapper w-300px h-300px'
                          style={{
                            backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row mb-6'>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        onClick={() => handleGetTheImageFromLocal()}
                        style={{
                          backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIyajnnESu31PsnREKF5_lLqP9T9_7xToL2zF65DyDhA&s)`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Create a complaint'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {ProfileDetails}
// date range store status employee in the transations
