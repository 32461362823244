// @ts-nocheck
import {Column} from 'react-table'
import {TranscationInfoCell} from './TranscationInfoCell'
import {TranscationLastLoginCell} from './TranscationLastLoginCell'
import {TranscationTwoStepsCell} from './TranscationTwoStepsCell'
import {TranscationActionsCell} from './TranscationActionsCell'
import {TranscationCustomHeader} from './TranscationCustomHeader'
import {User} from '../../core/_models'
import {OrderDetailInfoCell} from './OderDetailInfoCell'
import EmployeeCell from './Employee'
import StartDate from './StartDate'
import DueBy from './DueBy'
import { ChargesService } from './ChargesService'

const TranscationsColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <TranscationSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <TranscationSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Service details' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <TranscationInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader
        tableProps={props}
        title='Customer details'
        className='min-w-125px'
      />
    ),
    id: 'customerDetail',
    Cell: ({...props}) => <OrderDetailInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Allocated To' className='min-w-125px' />
    ),
    id: 'allocated_to',
    Cell: ({...props}) => <TranscationLastLoginCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='branch' className='min-w-125px' />
    ),
    id: 'branch',
    Cell: ({...props}) => <TranscationTwoStepsCell branch={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    id: 'start_date',
    Cell: ({...props}) => <StartDate startDate={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <EmployeeCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Due By' className='min-w-125px' />
    ),
    id: 'due_by',
    Cell: ({...props}) => (
      <DueBy
        startDate={props.data[props.row.index].createdAt}
        endDate={props.data[props.row.index].updatedAt}
      />
    ),
  },
  {
    Header: (props) => (
      <TranscationCustomHeader tableProps={props} title='Charges' className='min-w-125px' />
    ),
    id: 'charges',
    Cell: ({...props}) => (
      <ChargesService finalPricing={props.data[props.row.index].finalPricing} />
    ),
  },
  {
    Header: (props) => (
      <TranscationCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TranscationActionsCell user={props.data[props.row.index]} />,
  },
]

export { TranscationsColumns }
// genereate job sheet

// name , phone address job shett customer details
// merhcnat copy and customer copy

// generate bill
// show the pricing value
// is only active status complted by technition
