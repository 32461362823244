import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {
  AddProduct,
  Product,
  ProductsQueryResponse,
  SearchProduct,
  Service,
  UpdateComplaint,
  User,
  UsersQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SERVICE_URL = `${API_URL}/service`
const PRODUCT_SEARCH_URL = `${API_URL}/product`
const USER_SEARCH_URL = `${API_URL}/user`
const UPDATE_PRODUCT_URL = `${API_URL}/product`
const UPDATE_USER_URL = `${API_URL}/user`

const createService = (
  service: Service,
  token: string | undefined
): Promise<Product | undefined> => {
  return axios
    .post(`${SERVICE_URL}/register`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      service,
    })
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}
const searchProduct = (
  name: string,
  token: string | undefined
): Promise<Array<Product> | undefined> => {
  return (
    axios
      .get(`${PRODUCT_SEARCH_URL}/search?name=${name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      // .then((response: AxiosResponse<Response<Product>>) => response.data.data)
      .then((response: AxiosResponse<Response<Array<Product>> | undefined>) => response.data?.data)
  )
}
const searchUser = (name: string, token: string | undefined): Promise<Array<User> | undefined> => {
  return (
    axios
      .get(`${USER_SEARCH_URL}/search?name=${name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      // .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: AxiosResponse<Response<Array<User>> | undefined>) => response.data?.data)
  )
}
const updateProduct = (token: string | undefined, data: Product): Promise<Product | undefined> => {
  return axios
    .put(`${UPDATE_PRODUCT_URL}/${data._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
const createProduct = (
  product: Product,
  token: string | undefined
): Promise<Product | undefined> => {
  return axios
    .post(`${UPDATE_PRODUCT_URL}/register`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      product,
    })
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
const updateUserComplaint = (
  user: UpdateComplaint,
  token: string | undefined
): Promise<Product | undefined> => {
  return axios
    .put(`${UPDATE_USER_URL}/complaint/${user._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      user,
    })
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
const getTTKPLFromApi = async (token: string | undefined) => {
  let {data} = await axios.get(`${SERVICE_URL}/ttkpl`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  console.log(data.data)

  return data.data
}
export {
  createService,
  searchProduct,
  searchUser,
  updateProduct,
  createProduct,
  updateUserComplaint,
  getTTKPLFromApi,
}
