/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import ServiceActionDropDown from './components/ServiceActionDropDown'
import EmployeeAllocation from './components/EmployeeAllocation'
import {useGetServiceById} from './components/core/QueryResponseProvider'
import moment from 'moment'
import GenerateJobSheet from './components/generatepdf/GenerateJobSheet'
import JsPDF from 'jspdf'
import {Service} from './components/core/_models'
import {useAuth} from '../auth'
import GenerateBill from './components/generatepdf/GenerateBill'
import toast from 'react-hot-toast'
const AccountHeader: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {id} = useParams()
  const {data, isFetching} = useGetServiceById(id)
  const {currentUser} = useAuth()

  console.log('data.finalPricing.length', data?.finalPricing)
  const printHandler = async (service: Service) => {
    const generatePdfElement1 = <GenerateJobSheet service={service} name='Merchant' />
    const generatePdfElement2 = <GenerateJobSheet service={service} name='Customer' />

    const printElement1 = ReactDOMServer.renderToString(generatePdfElement1)
    const printElement2 = ReactDOMServer.renderToString(generatePdfElement2)

    const doc = new JsPDF({
      format: 'a4',
      unit: 'px',
    })

    // Add content from the first page
    doc.html(printElement1, {
      callback: async function (doc) {
        // Add a new page for the second content
        const firstPageHeight = doc.internal.pageSize.getHeight()

        // Add content from the second page
        doc.html(printElement2, {
          y: firstPageHeight / 2,
          callback: async function (doc) {
            // Save the PDF with both pages
            await doc.save(`job-sheet-${moment(Date.now()).format('DD/MM/YYYY')}`)
          },
          width: 350,
          margin: 23,
        })
      },
      width: 350,
      margin: 23,
    })
  }

  const generateBillHandler = async (service: Service) => {
    const generatePdfElement1 = <GenerateBill service={service} name='Merchant' />
    const generatePdfElement2 = <GenerateBill service={service} name='Customer' />

    const printElement1 = ReactDOMServer.renderToString(generatePdfElement1)
    const printElement2 = ReactDOMServer.renderToString(generatePdfElement2)

    const doc = new JsPDF({
      format: 'a4',
      unit: 'px',
    })

    // Add content from the first page
    doc.html(printElement1, {
      callback: async function (doc) {
        // Add a new page for the second content
        const firstPageHeight = doc.internal.pageSize.getHeight()

        // Add content from the second page
        doc.html(printElement2, {
          y: firstPageHeight / 2,
          callback: async function (doc) {
            // Save the PDF with both pages
            await doc.save(`Bill-${moment(Date.now()).format('DD/MM/YYYY')}`)
          },
          width: 350,
          margin: 23,
        })
      },
      width: 350,
      margin: 23,
    })
  }

  if (data) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              {data.product?.productPicture && data.product.productPicture.length > 0 ? (
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={data.product?.productPicture[0].Location} alt='Metronic' />
                </div>
              ) : (
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img
                    src={
                      'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                    }
                    alt='Metronic'
                  />
                </div>
              )}
            </div>
            {/* // Product name ( online complaint id/ complaint id/ Status) ttk */}
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center flex-wrap mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data.product?.productName}
                    </a>
                    <a href='#' className='text-gray-800 text-hover-primary  me-1'>
                      {data.TTKPLComplaintsNumber
                        ? `(TTKPL: ${data.TTKPLComplaintsNumber}/ Ticket number: ${
                            data.storeCentricId?.split('/')[
                              data.storeCentricId?.split('/').length - 2
                            ]
                          }/${
                            data.storeCentricId &&
                            data.storeCentricId?.split('/')[
                              data.storeCentricId?.split('/').length - 1
                            ]
                          })`
                        : `(Ticket number: ${
                            data?.storeCentricId
                              ? data.storeCentricId?.split('/')[
                                  data.storeCentricId?.split('/').length - 2
                                ]
                              : ''
                          }/${
                            data?.storeCentricId
                              ? data.storeCentricId?.split('/')[
                                  data.storeCentricId?.split('/').length - 1
                                ]
                              : ''
                          })`}
                    </a>
                  </div>

                  <div className='d-flex w-100 flex-wrap fw-bold fs-6 mb-4 mt-2 flex-column  pe-2'>
                    <div className='d-flex w-100'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 gap-2 text-hover-primary me-5 mb-2'
                      >
                        <span className='text-gray-800'>Product Code</span>
                        {data.product?.productCode}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2 '
                      >
                        <span className='text-gray-800'>Start Date</span>
                        {moment(data.createdAt).format('DD/MM/YYYY')}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2 '
                      >
                        <span className='text-gray-800'>End Date</span>
                        {moment(data.updatedAt).format('DD/MM/YYYY')}
                      </a>
                    </div>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 gap-1 text-hover-primary mb-2'
                    >
                      <span className='text-gray-800'>Due by</span>
                      {moment(data.updatedAt).diff(data.createdAt, 'days') === 0
                        ? 1 + 'day'
                        : moment(data.updatedAt).diff(data.createdAt, 'days') + 'days'}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2'
                    >
                      <span className='text-gray-800'>Status</span>
                      {data.status}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2'
                    >
                      <span className='text-gray-800'>Customer</span>
                      <span>{data.user ? `${data.user?.fullName} / ${data.user.phone}` : ''}</span>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2'
                    >
                      <span className='text-gray-800 w-85px'>Allocated to</span>
                      <span>
                        {data.allocatedTechnication
                          ? `${data.allocatedTechnication?.fullName} / ${data.allocatedTechnication?.phone}`
                          : ''}
                      </span>
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 pe-2 gap-2 text-hover-primary mb-2'
                    >
                      <span className='text-gray-800 w-85px'>Booked By</span>
                      <span>
                        {data.bookedBy
                          ? `${data?.bookedBy?.fullName} / ${
                              data?.bookedBy.branch ? data.bookedBy.branch?.storeCode + '/' : ''
                            }  ${data?.bookedBy?.phone}`
                          : ''}
                      </span>
                    </a>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <ServiceActionDropDown />
                  </div>
                  {currentUser && currentUser.user.is_admin && (
                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <EmployeeAllocation />
                    </div>
                  )}
                  {/* </div> */}
                  {/* <div className='d-flex'> */}
                  <div
                    style={{backgroundColor: '#009ef7'}}
                    className='btn text-light h-75 me-2 px-1 py-2'
                    onClick={() => {
                      printHandler(data)
                    }}
                  >
                    Generate job sheet
                  </div>

                  <button
                    style={{backgroundColor: '#009ef7'}}
                    className='btn text-light h-25 px-1 py-2'
                    onClick={() => generateBillHandler(data)}
                    disabled={data.finalPricing && data.finalPricing.length > 0 ? false : true}
                  >
                    Generate bill
                  </button>
                  {/* </div> */}
                </div>
                {/* <div className='d-flex my-4'>
                <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a>
                <a
                  href='#'
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Hire Me
                </a>
                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <Dropdown1 />
                </div>
              </div> */}
              </div>
              {/* <GenerateBill service={data} name='Customer' />{' '} */}
              {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>4500$</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr065.svg'
                        className='svg-icon-3 svg-icon-danger me-2'
                      />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>60%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/service-detail/${id}/overview` && 'active')
                  }
                  to={`/apps/service-detail/${id}/overview`}
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/service-detail/${id}/priductimages` && 'active')
                  }
                  to={`/apps/service-detail/${id}/priductimages`}
                >
                  Product and warranty card images
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/service-detail/${id}/employenotes` && 'active')
                  }
                  to={`/apps/service-detail/${id}/employenotes`}
                >
                  Employee notes
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/service-detail/${id}/estimatedpricing` &&
                      'active')
                  }
                  to={`/apps/service-detail/${id}/estimatedpricing`}
                >
                  Estimated pricing
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  } else {
    return <div>{isFetching ? 'Please wait...' : 'no record found....'}</div>
  }
}

export {AccountHeader}
// hide the admin profile from the employee
// he cant see the employee mangement section
// he just see his profile in admin section he cannt edit his profile
// he can create a complaint he can't assign to the employee
// he cannt add the store he cnanot edit the store

// two print of this merchnat and customer
