import {FC} from 'react'

type Props = {
  last_login?: string
}

const StoreLastLoginCell: FC<Props> = ({last_login}) => (
  <div className='fw-bolder'>{last_login}</div>
)

export {StoreLastLoginCell}
