import {useMutation, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getStoreById, updateStore} from './_requests'
import {useAuth} from '../../../auth'
import {Store} from './_models'

export const useGetStoreById = (id: any) => {
  const {auth} = useAuth()
  // useEffect(() => {
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.STORE_LIST_BY_ID}-${id}`,
    () => {
      return getStoreById(id, auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // }, [])
  return {isFetching, refetch, data}
}
export const useUpdateStoreById = (id: any) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError, data} = useMutation(
    (store: Store) => updateStore(auth?.access_token, store),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.STORE_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError, data}
}
