// @ts-nocheck
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import moment from 'moment'
import {Link, NavLink, useParams, useNavigate} from 'react-router-dom'
import {NoteModal} from '../../../../../_metronic/partials/modals/notes-modal/NoteModal'
import {useCreateNote, useGetNotes, useGetServiceById} from '../core/QueryResponseProvider'
import toast from 'react-hot-toast'
import {createNote, updateServiceNote} from '../core/_requests'
import {useAuth} from '../../../auth'

// import { useGetSubAdminByIdQuery, useUpdatePasswordMutation, useUpdateSubAdminDetailsMutation } from '../../../../redux/api'

const EmployeeNotes: React.FC = () => {
  const {id} = useParams()
  const {auth} = useAuth()
  const navigate = useNavigate()
  const {data: service} = useGetServiceById(id)
  const [isLoading, setLoading] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const {data} = useGetServiceById(id)
  console.log(data)

  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [date, setDate] = useState('')
  const {mutate} = useCreateNote(id)
  const callGetNotes = async () => {}
  const callDelNote = async (_id: any) => {}

  const callUpdateNote = async (_id: any, belongsTo: any) => {}

  useEffect(() => {
    callGetNotes()
  }, [])

  const [showAddModal, setShowAddModal] = useState(false)
  const handleAddNote = async () => {
    console.log(title, desc, date)
    try {
      setLoading(true)
      const newlyNote = await createNote(auth?.access_token, {
        title,
        desc,
        date,
        belongsTo: data?._id,
      })
      await mutate({
        title,
        desc,
        date,
        belongsTo: data?._id,
      })
      console.log('this is the newly note', newlyNote)

      toast.success('note add successfully')
      if (newlyNote?._id) {
        // update the service note array
        let newServiceNote = await updateServiceNote(auth?.access_token, id, {
          notes: newlyNote?._id,
        })
        console.log(newServiceNote)
      }
      setDesc('')
      setTitle('')
      setDate('')
      setLoading(false)
      setShowAddModal(false)
    } catch (error) {
      toast.error('Something went wrong')
      setLoading(false)
    }
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10 container'>
        <div
          className='card-header border-0 cursor-pointer'
          // role="button"
          // data-bs-toggle="collapse"
          // data-bs-target="#kt_account_profile_details"
          // aria-expanded="true"
          // aria-controls="kt_account_profile_details"
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Notes</h3>
          </div>

          {showAddModal && (
            <NoteModal
              setTitle={(e) => {
                setTitle(e)
              }}
              date={date}
              setDate={(e) => {
                setDate(e)
              }}
              loading={isLoading}
              setDesc={(e) => {
                setDesc(e)
              }}
              title={title}
              desc={desc}
              handleClose={() => {
                setShowAddModal(false)
              }}
              onSubmit={(e) => {
                handleAddNote()
              }}
              show={showAddModal}
            />
          )}
          <div className='mt-3'>
            <button
              onClick={(e) => {
                e.preventDefault()
                setTitle('')
                setDesc('')
                setDate('')
                setShowAddModal(true)
              }}
              // href={item?.mediaUrl}
              target='_blank'
              style={{
                width: '100%',
                padding: '15px',
              }}
              className='btn btn-sm btn-icon btn-primary btn-active-primary me-2'
            >
              <span className='svg-icon svg-icon-5 m-0'>
                <i className='fa fa-plus' aria-hidden='true'></i> Add Note
              </span>
            </button>
          </div>
        </div>
        {/* {console.log("errors",formik.errors)} */}

        <div id='kt_account_profile_details' className='collapse show'>
          {/* <UserEditModalForm /> */}
          <table
            id='kt_file_manager_list'
            data-kt-filemanager-table='folders'
            className='table align-middle table-row-dashed fs-6 gy-5'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check='true'
                      data-kt-check-target='#kt_file_manager_list .form-check-input'
                      value='1'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Title</th>
                <th className='min-w-10px'>Description</th>
                <th className='min-w-125px'>Date</th>
                <th className='w-125px'></th>
              </tr>
            </thead>
            {service && service.notes.length > 0 ? (
              <>
                <tbody className='fw-semibold text-gray-600'>
                  {service.notes.map((not, i) => {
                    return (
                      <tr key={not._id}>
                        <td></td>
                        <td>{not.title}</td>
                        <td>{not.desc}</td>
                        <td>{moment(not.date).format('MM/DD/YYYY')}</td>
                        <td></td>
                      </tr>
                    )
                  })}
                </tbody>
              </>
            ) : (
              <div>No record found</div>
            )}
          </table>
        </div>
      </div>
      {/* <SignInMethod /> */}
    </>
  )
}
export default EmployeeNotes
