import {FC} from 'react'

type Props = {
  last_login?: string
}

const ProductSku: FC<Props> = ({last_login}) => (
  <div className='fw-bolder'>{last_login ? `${last_login} %` : ''}</div>
)

export {ProductSku}
