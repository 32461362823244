import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User} from '../core/_models'
import {ServicesListLoading} from '../components/loading/ServicesListLoading'
import {ServicesListPagination} from '../components/pagination/ServicesListPagination'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useAuth} from '../../../../../auth'
import {filterUsers, searchService} from '../core/_requests'
import {reportComplaintsDetail} from '../../../../../../../_metronic/helpers/constants'

const ServicesTable = () => {
  const users = useQueryResponseData()
  console.log('this is the data generate tin the service table', users)
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const isLoading = useQueryResponseLoading()
  const [data, setData] = useState(users)
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  useEffect(() => {
    console.log('hi filter ka data change hova hai')
    console.log(state.filter)
    const filter = async () => {
      try {
        if (state.filter) {
          const dataFil = await filterUsers(state.filter, auth?.access_token)
          if (dataFil.data) {
            localStorage.setItem(reportComplaintsDetail, JSON.stringify(dataFil.data))
            setData(dataFil.data)
          }

          console.log('dataFil.data', dataFil.data)
        }
        if (state.search) {
          const dataFil = await searchService(state.search, auth?.access_token)
          if (dataFil.data) {
            setData(dataFil.data)
            console.log(dataFil.data)
          }
        }
        if (!state.filter && !state.search) {
          setData(users)
        }
      } catch (error) {}
    }
    filter()
  }, [state.filter, state.search, users])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ServicesListPagination />
      {isLoading && <ServicesListLoading />}
    </KTCardBody>
  )
}

export {ServicesTable}
