import moment from 'moment'
import {FC} from 'react'

type Props = {
  updatedAt?: string
}

const ServiceEndedAt: FC<Props> = ({updatedAt}) => (
  <>
    {updatedAt ? (
      <div className='fw-bolder'>{moment(updatedAt).format('DD-MM-YYYY')}</div>
    ) : (
      <div className='fw-bolder'>Not select</div>
    )}
  </>
)

export {ServiceEndedAt}
