import React, {useEffect, useState} from 'react'
import {QUERIES, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useGetEmployeeById, useUpdateEmployeeById} from '../../core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {queryClient} from '../../../../../..'
import {uploadImageOnS3} from '../../../../../../_metronic/helpers/uitils/functions'
// import {Store} from '../../../../store-details/components/core/_models'
import {getStores} from '../../../../add-employee/components/core/_request'
import {useAuth} from '../../../../auth'
import { Store } from '../../../../add-employee/components/core/_models'

const profileDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('First name is required'),
  phone: Yup.string().required('Contact phone is required'),
  email: Yup.string().required('email is required'),
  branch: Yup.object().shape({
    _id: Yup.string(),
    storeName: Yup.string(),
    storeCity: Yup.string(),
  }),
  designation: Yup.string().required('designation is required'),
  password: Yup.string(),
  profilePicture: Yup.string(),
})

const ProfileDetails: React.FC = () => {
  const {id} = useParams()
  const {data: response, isRefetching, refetch} = useGetEmployeeById(id)
  const [stores, setStores] = useState<Store[]>()
  const [data, setData] = useState<IProfileDetails | undefined>(response ? response : initialValues)
  const [loading, setLoading] = useState(false)
  const {mutate} = useUpdateEmployeeById(id)
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const {auth} = useAuth()
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0]

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          console.log(selectedFile)
          const data = await uploadImageOnS3({file: selectedFile})
          setProfilePicture(data)

          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  useEffect(() => {
    setData(response && response)
    const fetchStores = async () => {
      const data = await getStores(auth?.access_token)
      setStores(data?.data)
    }
    fetchStores()
  }, [response])

  const formik = useFormik<IProfileDetails>({
    initialValues: data ? data : initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log('this is the values', values)
      console.log(values.branch?._id === data?.branch?._id)

      try {
        delete values.password
        if (profilePicture) {
          values.profilePicture = profilePicture
        }
        await mutate(values)
        setLoading(false)

        return toast.success('User updated successfully')
      } catch (error) {
        setLoading(false)
        return toast.error('Something went wrong')
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {profilePicture ? (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${profilePicture})`}}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${data?.profilePicture})`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('fullName')}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.fullName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Email id</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email id'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Branch</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('branch._id')}
                >
                  {stores &&
                    stores?.map((store) => {
                      return (
                        <option key={store._id} value={store._id}>
                          {`${store.storeName} - ${store.storeState}`}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.branch && formik.errors.branch && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.branch}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Designation</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Designation'
                  {...formik.getFieldProps('designation')}
                />
                {formik.touched.designation && formik.errors.designation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.designation}</div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
