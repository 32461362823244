import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useGetServiceById, useUpdateServiceEmployeeById} from './core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {useAuth} from '../../auth'
const serviceStatus = [
  'Pending',
  'Approved',
  'Progress',
  'Completed by employee',
  'Approved by admin',
  'Rejected',
]
const serviceStatusEmp = ['Pending', 'Approved', 'Progress', 'Completed by employee']
const ServiceActionDropDown = () => {
  const {id} = useParams()
  const {data: service, refetch} = useGetServiceById(id)
  const {currentUser} = useAuth()

  const {mutate, isSuccess, isLoading, isError} = useUpdateServiceEmployeeById(id)

  const handleServiceStatus = async (status: any) => {
    if (service && service.status !== 'Approved by admin') {
      try {
        const updaData = {
          status: status,
        }
        await mutate(updaData)
        refetch()
        toast.success(isSuccess ? 'Status updated successfully' : 'Please wait...')
      } catch (error) {
        toast.error(
          isError ? 'Error while update the status' : 'Internal server error please try again later'
        )
      }
    }
  }
  const handleServiceStatusEmp = async (status: any) => {
    if (service) {
      if(service.status === 'Completed by employee' && !currentUser?.user.is_admin) {
        toast.error("Only admin can update the status")
      } else {

        try {
          const updaData = {
            status: status,
          }
          await mutate(updaData)
          refetch()
          toast.success(isSuccess ? 'Status updated successfully' : 'Please wait...')
        } catch (error) {
          toast.error(
            isError ? 'Error while update the status' : 'Internal server error please try again later'
            )
          }
        }
    }
  }
  return (
    <>
      <a
        href='#'
        className='btn d-flex align-item-center btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {service?.status ? service?.status : 'Status'}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      {currentUser?.user.is_admin ? (
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {serviceStatus.map((status, i) => {
            return (
              <div key={i} onClick={() => handleServiceStatus(status)} className='menu-item px-3'>
                <a
                  className={`menu-link px-3 ${service?.status === status ? 'active' : ''}`}
                  data-kt-users-table-filter='delete_row'
                >
                  {status}
                </a>
              </div>
            )
          })}
        </div>
      ) : (
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {serviceStatusEmp.map((status, i) => {
            return (
              <div key={i} onClick={() => handleServiceStatusEmp(status)} className='menu-item px-3'>
                <a
                  className={`menu-link px-3 ${service?.status === status ? 'active' : ''}`}
                  data-kt-users-table-filter='delete_row'
                >
                  {status}
                </a>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default ServiceActionDropDown
