import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'


import {KTCard} from '../../../../../_metronic/helpers'
import { EmployesListHeader } from './components/header/EmployesListHeader'
import { EmployesTable } from './table/EmployesTable'
import { EmployeEditModal } from './employe-edit-modal/EmployeEditModal'

const EmployesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <EmployesListHeader />
        <EmployesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EmployeEditModal />}
    </>
  )
}

const EmployesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmployesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EmployesListWrapper}
