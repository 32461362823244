import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {TranscationsListFilter} from './TranscationsListFilter'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {generateBillInPdf} from '../../../../../../../../_metronic/helpers/crud-helper/generatePdfHelper'
import {User} from '../../core/_models'
import {reportRevenueDetail} from '../../../../../../../../_metronic/helpers/constants'
const TranscationsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const users = useQueryResponseData()

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const dataWithPricing = (d: User[]) => {
    if (d.length > 0) {
      let newData = d.filter((item) => {
        if (item.finalPricing) {
          console.log('final pricing', item.finalPricing)

          return +item.finalPricing > 0
        }
      })
      console.log('this is the new data', newData)

      return newData
    }
    return []
  }
  const generatePdf = () => {
    let ser = localStorage.getItem(reportRevenueDetail)
    if (!ser) {
      console.log('this is the ser', ser)
      generateBillInPdf(dataWithPricing(users), 'Transaction')
    } else {
      let jsonSer: User[] = JSON.parse(ser)
      generateBillInPdf(dataWithPricing(jsonSer), 'Transaction')
    }
  }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <TranscationsListFilter />

      {/* begin::Export */}
      <button onClick={() => generatePdf()} type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Transcation
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {TranscationsListToolbar}
