import moment from 'moment'
import {FC} from 'react'

type Props = {
  store_code?: string
}

const StoreCode: FC<Props> = ({store_code}) => (
  <div className='fw-bolder'>{store_code ? store_code : ''}</div>
)

export default StoreCode
