// @ts-nocheck
import {Column} from 'react-table'
import {StoreInfoCell} from './StoreInfoCell'
import {StoreLastLoginCell} from './StoreLastLoginCell'
import {StoreTwoStepsCell} from './StoreTwoStepsCell'
import {StoreActionsCell} from './StoreActionsCell'
import {StoreSelectionCell} from './StoreSelectionCell'
import {StoreCustomHeader} from './StoreCustomHeader'
import {StoreSelectionHeader} from './StoreSelectionHeader'
import {User} from '../../core/_models'
import {StoreStatusCell} from './StoreStatusCell'
import StoreCreatedDate from './StoreCreatedDate'
import StoreCode from './StoreCode'

const storesColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <StoreSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <StoreSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Store Name' className='min-w-125px' />
    ),
    id: 'store_name',
    Cell: ({...props}) => <StoreInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Status' className='text-start min-w-100px' />
    ),
    id: 'status',
    Cell: ({...props}) => <StoreStatusCell id={props.data[props.row.index]?.is_deactivated} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Phone number' className='min-w-125px' />
    ),
    id: 'phone_number',
    Cell: ({...props}) => (
      <StoreLastLoginCell last_login={props.data[props.row.index]?.storePhoneNumber} />
    ),
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    id: 'location',
    Cell: ({...props}) => <StoreTwoStepsCell two_steps={props.data[props.row.index].storeCity} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='code' className='min-w-125px' />
    ),
    id: 'store_code',
    Cell: ({...props}) => <StoreCode store_code={props.data[props.row.index].storeCode} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Store created date' className='min-w-125px' />
    ),
    id: 'store_created_date',
    Cell: ({...props}) => <StoreCreatedDate two_steps={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <StoreActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {storesColumns}
