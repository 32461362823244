import {FC} from 'react'

type Props = {
  ttkplNumber?: string
}

const TTKPLNumber: FC<Props> = ({ttkplNumber}) => (
  <>
    <div className='fw-bolder'>{ttkplNumber}</div>
  </>
)

export {TTKPLNumber}
