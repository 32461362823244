// @ts-nocheck
import {Column} from 'react-table'
import {EmployeInfoCell} from './EmployeInfoCell'
import {EmployeLastLoginCell} from './EmployeLastLoginCell'
import {EmployeTwoStepsCell} from './EmployeTwoStepsCell'
import {EmployeActionsCell} from './EmployeActionsCell'
import {EmployeSelectionCell} from './EmployeSelectionCell'
import {EmployeCustomHeader} from './EmployeCustomHeader'
import {EmployeSelectionHeader} from './EmployeSelectionHeader'
import {User} from '../../core/_models'
import {EmployeStatusCell} from './EmployeStatusCell'
import {EmployeDesignation} from './EmployeeDesignation'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <EmployeSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <EmployeSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Employee name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <EmployeInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Designation' className='min-w-125px' />
    ),
    id: 'designation',
    Cell: ({...props}) => (
      <EmployeDesignation two_steps={props.data[props.row.index].designation} />
    ),
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Phone number' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({...props}) => <EmployeLastLoginCell last_login={props.data[props.row.index].phone} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Branch' className='min-w-125px' />
    ),
    id: 'branch',
    Cell: ({...props}) => <EmployeTwoStepsCell branch={props.data[props.row.index].branch} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Status' className='text-start min-w-100px' />
    ),
    id: 'status',
    Cell: ({...props}) => <EmployeStatusCell id={props.data[props.row.index].is_deactivated} />,
  },
  {
    Header: (props) => (
      <EmployeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
