import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
  useQueryTotalCount,
} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User, UsersQueryResponse} from '../core/_models'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {EmployesListLoading} from '../components/loading/EmployesListLoading'
import {EmployesListPagination} from '../components/pagination/EmployesListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useAuth} from '../../../../auth'
import {filterUsers, searchEmployee} from '../core/_requests'
import {AxiosResponse} from 'axios'

const EmployesTable = () => {
  const users = useQueryResponseData()
  const {state} = useQueryRequest()
  const pagination = useQueryResponsePagination()
  const totalCount = useQueryTotalCount()

  const {auth} = useAuth()
  const isLoading = useQueryResponseLoading()
  const [data, setData] = useState(users)
  // console.log('this is the employees', data)
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  useEffect(() => {
    // console.log('hi filter ka data change hova hai')
    // console.log(state)
    const filter = async () => {
      if (state.filter) {
        const dataFil = await filterUsers(state.filter, auth?.access_token)
        if (dataFil.data) {
          setData(dataFil.data)
        }
      }
      if (state.search) {
        console.log('search pr render ho raha hai')

        const data = await searchEmployee(state.search, auth?.access_token)
        if (data.data) {
          setData(data.data)

          console.log('search data', data)
        }
      }
      if (!state.filter && !state.search) {
        setData(users)
      }
      console.log('this is the actual data', data)
    }
    filter()
  }, [state.filter, state.search, users])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? <EmployesListLoading /> : 'No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount && totalCount > 10 && <EmployesListPagination />}
    </KTCardBody>
  )
}

export {EmployesTable}
