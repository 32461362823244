import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Store, User, UsersQueryResponse, filter} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_SERVICES_URL = `${API_URL}/service`
const GET_STORES_URL = `${API_URL}/store`
const GET_USERS_URL = `${API_URL}/employee`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(
      `${GET_SERVICES_URL}/filter?status=undefined&startDate=undefined&endDate=undefined&employee=undefined&store=undefined`
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}
const getAllStores = (token: string | undefined): Promise<Array<Store> | undefined> => {
  return axios
    .get(`${GET_STORES_URL}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<Store>>>) => response.data)
    .then((response: Response<Array<Store>>) => response.data)
}
const filterService = (filter: filter, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(
      `${GET_SERVICES_URL}/filter?status=undefined&startDate=${filter.startDate}&endDate=${filter.endDate}&employee=undefined&store=${filter.store}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
export {getUsers, getUserById, getAllStores, filterService}
