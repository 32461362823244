import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StoresListHeader} from './components/header/StoresListHeader'
import {StoresTable} from './table/StoresTable'
import {KTCard} from '../../../../../_metronic/helpers'

const StoresList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <StoresListHeader />
        <StoresTable />
      </KTCard>
    </>
  )
}

const StoresListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StoresList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StoresListWrapper}
