/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProvider'
import {Link} from 'react-router-dom'
import {generateBillInPdf, generateSingleBillInPdf} from '../../../../../../../_metronic/helpers/crud-helper/generatePdfHelper'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const TranscationActionsCell: FC<Props> = ({user}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary d-flex  btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <Link to={`/apps/transcation-details/${id}/overview`} className='menu-link px-3'>
            View order
          </Link>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <div className='menu-link px-3' onClick={() => generateSingleBillInPdf(user)}>
            generate bill
          </div>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {TranscationActionsCell}
