import {useMutation, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getProductById, updateProduct} from './_requests'
import {useAuth} from '../../../auth'
import {Product} from './_models'

export const useGetProductById = (id: any) => {
  const {auth} = useAuth()
  // useEffect(() => {
  const {isFetching, refetch, data} = useQuery(
    `${QUERIES.PRODUCT_LIST_BY_ID}-${id}`,
    () => {
      return getProductById(id, auth?.access_token)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // }, [])
  return {isFetching, refetch, data}
}
export const useUpdateProductId = (id: any) => {
  const queryClient = useQueryClient()

  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError} = useMutation(
    (product: Product) => updateProduct(auth?.access_token, product),
    {
      onSuccess: () => {
        console.log('successful update teh data')
        queryClient.refetchQueries(`${QUERIES.PRODUCT_LIST_BY_ID}-${id}`)
      },
    }
  )
  return {mutate, isLoading, isSuccess, isError}
}
