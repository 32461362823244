import {useMutation} from 'react-query'
import {createProduct} from './_request'

import {Product} from './_models'
import {useAuth} from '../../../auth'

// const mutation = useMutation((newTodo) => updateUser())
export const useCreateProfuct = () => {
  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError, data} = useMutation((profuct: Product) =>
    createProduct(profuct, auth?.access_token)
  )
  return {mutate, isLoading, isSuccess, isError, data}
}
