import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Store, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const UPDATE_URL = `${API_URL}/store`
const GET_PRODUCT_URL = `${API_URL}/store`

const getStoreById = (id: string, token: string | undefined): Promise<Store | undefined> => {
  return axios
    .get(`${GET_PRODUCT_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Store>>) => response.data)
    .then((response: Response<Store>) => response.data)
}
const updateStore = (token: string | undefined, data: Store): Promise<Store | undefined> => {
  return axios
    .put(`${UPDATE_URL}/${data._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<Store>>) => response.data)
    .then((response: Response<Store>) => response.data)
}
export {getStoreById, updateStore}
