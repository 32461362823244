export interface IProfileDetails {
  profilePicture?: string
  fullName?: string
  phone?: string
  additionalPhoneNumber?: string
  password?: string
  pincode?: string
  country?: string
  state?: string
  city?: string
  locality?: string
  landmark?: string
  customerAddress?: string
  email?: string
  nearByBranch?: Store
  customerType?: string
}
export type Store = {
  _id?: string
  storeName?: string
  storePhoneNumber?: string
  storeEmailId?: string
  storePincode?: string
  storeCountry?: string
  storeState?: string
  storeCity?: string
  is_deactivated?: Boolean
  storePicture?: string
  storeLocality?: string
  storeLandMark?: string
  createdAt?: string
  updatedAt?: string
}
export interface IProfileDetailsData {
  profilePicture?: string
  fullName?: string
  phone?: string
  additionalPhoneNumber?: string
  password?: string
  pincode?: string
  country?: string
  state?: string
  city?: string
  locality?: string
  landmark?: string
  customerAddress?: string
  email?: string
  nearByBranch?: Store
  customerType?: string
}
export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  profilePicture: '/media/avatars/300-1.jpg',
  fullName: '',

  phone: '',
  additionalPhoneNumber: '',
  pincode: '',
  country: '',
  state: '',
  city: '',
  locality: '',
  landmark: '',
  customerAddress: '',
  email: '',
  nearByBranch: {
    _id: '',
    storeName: '',
    storeState: '',
    storeCity:'',
  },
  customerType: '',
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
