import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useCreateStore} from '../../core/QueryResponseProvider'
import toast from 'react-hot-toast'
import {uploadImageOnS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {useNavigate} from 'react-router-dom'

const profileDetailsSchema = Yup.object().shape({
  storeName: Yup.string().required('storeName is required'),
  storePhoneNumber: Yup.string().required('storePhoneNumber is required'),
  storeEmailId: Yup.string().required('store Email Id is required'),
  storePincode: Yup.string().required('store Pincode is required'),
  storeCountry: Yup.string().required('store Country is required'),
  storeState: Yup.string().required('store State is required'),
  storeCity: Yup.string().required('store City is required'),
  storeLocality: Yup.string().required('store Locality is required'),
  storeLandMark: Yup.string().required('store LandMark is required'),
  storeCode: Yup.string().required('store Code is required'),
})

const ProfileDetails: React.FC = () => {
  // const [data, setData] = useState<IProfileDetails>(initialValues)
  const {mutate, isSuccess} = useCreateStore()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [storePicture, setStorePicture] = useState('')
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0]

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          console.log(selectedFile)
          const data = await uploadImageOnS3({file: selectedFile})
          setStorePicture(data)

          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (storePicture) {
          values.storePicture = storePicture
        }
        await mutate(values)
        toast.success('Store added Successfully')
        setTimeout(() => {
          navigate('/apps/store-management/stores')
        }, 2000)
        setLoading(false)
      } catch (error) {
        toast.error('Something went wrong please try again later')
        setLoading(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Store Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Store image</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {storePicture ? (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${storePicture})`}}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Store name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Store name'
                  {...formik.getFieldProps('storeName')}
                />
                {formik.touched.storeName && formik.errors.storeName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Store phone number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Store phone number'
                  {...formik.getFieldProps('storePhoneNumber')}
                />
                {formik.touched.storePhoneNumber && formik.errors.storePhoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storePhoneNumber}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Store Code</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='store code'
                  {...formik.getFieldProps('storeCode')}
                />
                {formik.touched.storeCode && formik.errors.storeCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeCode}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Store Email id</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='storeEmailId'
                  {...formik.getFieldProps('storeEmailId')}
                />
                {formik.touched.storeEmailId && formik.errors.storeEmailId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeEmailId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Pincode</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Pincode'
                  {...formik.getFieldProps('storePincode')}
                />
                {formik.touched.storePincode && formik.errors.storePincode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storePincode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Country</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                  {...formik.getFieldProps('storeCountry')}
                />
                {formik.touched.storeCountry && formik.errors.storeCountry && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeCountry}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>State</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                  {...formik.getFieldProps('storeState')}
                />
                {formik.touched.storeState && formik.errors.storeState && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeState}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  {...formik.getFieldProps('storeCity')}
                />
                {formik.touched.storeCity && formik.errors.storeCity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeCity}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Locality</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Locality'
                  {...formik.getFieldProps('storeLocality')}
                />
                {formik.touched.storeLocality && formik.errors.storeLocality && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeLocality}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Landmark</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Landmark'
                  {...formik.getFieldProps('storeLandMark')}
                />
                {formik.touched.storeLandMark && formik.errors.storeLandMark && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.storeLandMark}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
