/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {Link} from 'react-router-dom'
import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import {User} from '../../core/_models'
import {generateSingleBillInPdf} from '../../../../../../../../_metronic/helpers/crud-helper/generatePdfHelper'
type Props = {
  id: ID
}

const ServiceActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const users = useQueryResponseData()
  const generatePDF = (users: User[]) => {
    // initialize jsPDF
    const doc = new jsPDF()

    // define the columns we want and their titles (ensure column names match object properties)
    const tableColumn = ['Id', 'Name', 'Email', 'Role', 'Date On']

    // define an empty array of rows
    let tableRows: any = []

    // for each user, pass their data into an array
    for (let index = 0; index < users?.length; index++) {
      const element = users[index]
      const userData = [
        element._id,
        element.product?.productName,
        element.product?.productModalNumber,
        element.product?.productPrice,
        // Use 'YYYY-MM-DD' for date formatting
        moment(element.product?.createdAt).format('YYYY-MM-DD'),
      ]
      // push each user's info into a row
      tableRows.push(userData)
    }
    // startY is basically margin-top
    autoTable(tableColumn, tableRows)
    const date = new Date().toString().split(' ')
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
    // document title and margin-top + margin-left
    doc.text('Service data .', 14, 15)
    // we define the name of our PDF file.
    doc.save(`service_${dateStr}.pdf`)
  }
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <Link to={`/apps/service-detail/${id}/overview`} className='menu-item px-3'>
          <a className='menu-link px-3' data-kt-users-table-filter='delete_row'>
            View
          </a>
        </Link>
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => generateSingleBillInPdf(users, 'Revenue')}>
            generate Bill
          </a>
        </div> */}
        {/* end::Menu item */}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ServiceActionsCell}
