import {ID, Response} from '../../../../../../_metronic/helpers'
export type Store = {
  _id?: string
  storeName?: string
  storePhoneNumber?: string
  storeEmailId?: string
  storePincode?: string
  storeCountry?: string
  storeState?: string
  storeCity?: string
  is_deactivated?: Boolean
  storePicture?: string
  storeLocality?: string
  storeLandMark?: string
  storeCode?: string
  createdAt?: string
  updatedAt?: string
}
export type User = {
  _id?: string | undefined
  fullName?: string
  email?: string
  phone?: string
  password?: string
  branch?: Store
  designation?: string
  is_deactivated?: boolean
  profilePicture?: string
}
export interface filter {
  designation?: string
  branch?: string
}
export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  profilePicture: 'avatars/300-6.jpg',
  designation: 'Administrator',
  fullName: '',
  email: '',
}
