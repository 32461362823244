const QUERIES = {
  NOTES_LIST: 'notes-list',
  EMPLOYEE_LIST: 'employees-list',
  EMPLOYEE_BY_ID: 'employee-by-id',
  USER_BY_ID: 'user-by-id',
  USERS_LIST: 'users-list',
  USER_LIST_BY_ID: 'user-list-by-id',
  SEARCH_USER_BY_NAME: 'search-user-by-name',
  PRODUCTS_LIST: 'products-list',
  PRODUCT_LIST_BY_ID: 'product-list-by-id',
  SEARCH_PRODUCT_BY_NAME: 'search-product-by-name',
  STORES_LIST: 'stores-list',
  STORE_LIST_BY_ID: 'store-list-by-id',
  SERVICES_LIST: 'services-list',
  SERVICE_LIST_BY_ID: 'service-list-by-id',
  ALL_TICKETS_COUNT: 'all-tickets-count',
  ALL_TICKETS_DATA: 'all-tickets-data',
}

export {QUERIES}
