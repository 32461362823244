import {useEffect, useState} from 'react'
import {useQueryResponse} from '../../../modules/apps/employee-management/employes-list/core/QueryResponseProvider'
import {useQueryRequest} from '../../../modules/apps/employee-management/employes-list/core/QueryRequestProvider'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {filterEmployees, getAllEmployees, getAllStores} from './core/_requests'
import {Store} from './core/_models'
import {useAuth} from '../../../modules/auth'
import {User as TechnitionData} from '../../../modules/apps/employee-management/employes-list/core/_models'
import {useStartAndEndDateProvider} from './core/context/Provider'
const FilterByStoreAndEmp = () => {
  const {updateState} = useQueryRequest()
  const {auth} = useAuth()
  const {isLoading} = useQueryResponse()
  const [store, setSore] = useState<string | undefined>()
  const [allStores, setAllStores] = useState<Store[]>()
  const [employee, setEmployee] = useState<string | undefined>()
  const [employees, setEmployees] = useState<TechnitionData[] | undefined>()
  const startDate = null
  const endDate = null
  const {updateStartEndDate} = useStartAndEndDateProvider()

  useEffect(() => {
    const fetchAllStores = async () => {
      const allStoresResponse = await getAllStores(auth?.access_token)
      if (allStoresResponse) {
        setAllStores(allStoresResponse)
      }
    }

    fetchAllStores()
  }, [])
  useEffect(() => {
    const fetchEmployee = async () => {
      const data = await filterEmployees(store, auth?.access_token)
      setEmployees(data)
    }
    fetchEmployee()
  }, [store])
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateStartEndDate('undefined', 'undefined', 'undefined', 'undefined')
    setEmployee('')
    setSore('')
  }

  const filterData = () => {
    updateStartEndDate(startDate, endDate, store, employee)
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        store/employee
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Store:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setSore(e.target.value)}
              value={store}
            >
              <option value=''></option>
              {allStores && allStores?.length > 0 ? (
                allStores.map((store) => {
                  return (
                    <option key={store._id} value={store._id}>
                      {store.storeName}
                    </option>
                  )
                })
              ) : (
                <option value=''>no store found</option>
              )}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Employee:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setEmployee(e.target.value)}
              value={employee}
            >
              <option value=''></option>
              {employees && employees?.length > 0 ? (
                employees?.map((emp) => {
                  return (
                    <option onClick={() => setEmployee(emp._id)} key={emp._id} value={emp._id}>
                      {emp.fullName}
                    </option>
                  )
                })
              ) : (
                <option value=''>no employee found</option>
              )}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={() => filterData()}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export default FilterByStoreAndEmp
