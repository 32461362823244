import {useMutation, useQuery} from 'react-query'

import {DashBoardCounts} from './_models'
import {useAuth} from '../../../../modules/auth'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getAllServices, getServices} from './_requests'

// const mutation = useMutation((newTodo) => updateUser())
export const useGetTicketcCount = () => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ALL_TICKETS_COUNT}`,
    () => {
      return getServices()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return {
    isFetching,
    refetch,
    response,
  }
}

export const useGetTicketsData = () => {
  const {
    isFetching,
    refetch,
    data: ticketsData,
  } = useQuery(
    `${QUERIES.ALL_TICKETS_DATA}`,
    () => {
      return getAllServices()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return {
    isFetching,
    refetch,
    ticketsData,
  }
}
