import moment from 'moment'
import {FC} from 'react'

type Props = {
  startDate?: string
  endDate?: string
}

const DueBy: FC<Props> = ({startDate, endDate}) => (
  <div className='fw-bolder'>
    {moment(endDate).diff(startDate, 'days') === 0 ? 1 : moment(endDate).diff(startDate, 'days')}
  </div>
)

export default DueBy
