import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ServicesListWrapper} from './users-list/ServicesList'

const ServiceManagementBreadcrumbs: Array<PageLink> = [
  {
    title: 'Services Management',
    path: '/apps/service-management/services',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ServicesManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='services'
          element={
            <>
              <PageTitle breadcrumbs={ServiceManagementBreadcrumbs}>Services Management</PageTitle>
              <ServicesListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/service-management/services' />} />
    </Routes>
  )
}

export default ServicesManagementPage
