import {useMutation} from 'react-query'
import {createUser} from './_request'

import {User} from './_models'
import {useAuth} from '../../../auth'

// const mutation = useMutation((newTodo) => updateUser())
export const useCreateUser = () => {
  const {auth} = useAuth()
  const {mutate, isLoading, isSuccess, isError, data} = useMutation((newUser: User) =>
    createUser(newUser, auth?.access_token)
  )

  return {mutate, isLoading, isSuccess, isError, data}
}
