import {User as UserData} from '../../../apps/user-management/users-list/core/_models'
import {User as TechnitionData} from '../../../apps/employee-management/employes-list/core/_models'
import {Product} from '../../../apps/product-management/products-list/core/_models'
export type Store = {
  _id?: string
  storeName?: string
  storePhoneNumber?: string
  storeEmailId?: string
  storePincode?: string
  storeCountry?: string
  storeState?: string
  storeCode?: string
  storeCity?: string
  is_deactivated?: Boolean
  storePicture?: string
  storeLocality?: string
  storeLandMark?: string
  createdAt?: string
  updatedAt?: string
}
type Images = {
  Location: string
  _id?: string
}
export interface IProfileDetails {
  _id?: string
  product?: Product | null
  user?: UserData | null
  allocatedTechnication?: TechnitionData | null
  productPicture?: string[]
  natureOfComplaint?: string | null | undefined
  status?: string | null
  repairType?: string | null | undefined
  productCondition?: string | null
  repairClass?: string | null | undefined
  specialInstructionByCustomer?: string | null
  natureOfComplaints?: string | null
  allocatedBranch?: Store | null
  createdAt?: string | null
  updatedAt?: string | null
  TTKPLComplaintsNumber?: string | null
  estimatedPricing?: string | null
  finalPricing?: string | null
  repairTypeOther?: string
  startDate?: string | null
  endDate?: string | null
  warrantyCardImages?: Images[]
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  natureOfComplaint: '',
  repairType: '',
  productCondition: '',
  repairClass: '',
  specialInstructionByCustomer: '',
  natureOfComplaints: '',
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
