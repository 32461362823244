/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {useGetServiceById} from './core/QueryResponseProvider'
import moment from 'moment'
import {useAuth} from '../../auth'

export function Overview() {
  const {id} = useParams()
  const {data, isFetching} = useGetServiceById(id)
  const {currentUser} = useAuth()
  if (data) {
    return (
      <>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Service Details</h3>
            </div>
            {data.status !== 'Approved by admin' && data.status !== 'Completed by employee' ? (
              <Link
                to={`/apps/service-detail/${id}/edit`}
                className='btn btn-primary align-self-center'
              >
                Edit Service
              </Link>
            ) : (
              <>
                {data.status === 'Completed by employee' && (
                  <>
                    {/* Overview section (hide edit service for all) */}
                    {currentUser?.user.is_admin ? (
                      <Link
                        to={`/apps/service-detail/${id}/edit`}
                        className='btn btn-primary align-self-center'
                      >
                        Edit Service
                      </Link>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'> Repair Type</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.repairType}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'> Product Condition</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.productCondition}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'> Repair Class</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.repairClass}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'> Nature of Comaplint</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.natureOfComplaint}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Special Instruction by Customer</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.specialInstructionByCustomer}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Estimated Price</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {data.estimatedPricing ? data.estimatedPricing : 0}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Final Price</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {data.finalPricing ? data.finalPricing : 0}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Due By</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {moment(data.updatedAt).diff(data.createdAt, 'days') === 0
                    ? 1
                    : moment(data.updatedAt).diff(data.createdAt, 'days')}
                </span>
              </div>
            </div>
            {/* <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Store status</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>Yes</span>
            </div>
          </div> */}
          </div>
        </div>
      </>
    )
  } else {
    return <div>{isFetching ? 'Please wait...' : 'no record fouond'}</div>
  }
}
//header
// Product name ( online complaint id/ complaint id/ Status) ttk
// header line 1
//  id , strat of service , end data of service , due
// customer
// allocate to

// remove the detail
// service detail
// Complaint date and time
// 12/05/2023
// Complaint End Time

// attached payment proved

// rename -> product and warranty card images
// capitlize the names

// approved by admin - new
// completed by empoyee

// mobile number and email id unique
// phone number unique
