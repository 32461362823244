import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Store, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/store`

const getStores = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${GET_USERS_URL}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const updateUser = (store: Store): Promise<Store | undefined> => {
  return axios
    .post(`${USER_URL}/${store._id}`, store)
    .then((response: AxiosResponse<Response<Store>>) => response.data)
    .then((response: Response<Store>) => response.data)
}
const searchStore = (search: string, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}/search?name=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${GET_USERS_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getStores, deleteUser, deleteSelectedUsers, updateUser, searchStore}
