import moment from 'moment'
import {FC} from 'react'

type Props = {
  two_steps?: string
}

const ProductTwoStepsCell: FC<Props> = ({two_steps}) => (
  <>
    <div className='fw-bolder'>{moment(two_steps).format('MM/DD/YYYY')}</div>
  </>
)

export {ProductTwoStepsCell}
