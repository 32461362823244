/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {Service} from '../../core/_models'
import {Link, useLocation} from 'react-router-dom'

type Props = {
  user: Service
}

const EmployeInfoCell: FC<Props> = ({user}) => {
  const location = useLocation()
  const screen = location.pathname.split('/')[3]

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {user.product?.productPicture &&
          user.product?.productPicture?.length > 0 &&
          user.product?.productPicture ? (
            <div className='symbol-label'>
              <img
                src={user.product?.productPicture[0].Location}
                alt={'product'}
                className='w-100'
              />
            </div>
          ) : (
            <div className='symbol-label'>
              <img
                src={
                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyMj29LDxaI5bQEkRPvHCH4iPQfjrvfPsSkM33dWtFIw&s'
                }
                alt={'image'}
                className='w-100'
              />
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <div
          // to={`/apps/employe-details/${user.id}/overview`}
          className='text-gray-800 text-hover-primary mb-1'
        >
          {user.product?.productName}
        </div>
        <span>{user.product?.productCode}</span>
      </div>
    </div>
  )
}

export {EmployeInfoCell}
