import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryTotalCount,
} from '../core/QueryResponseProvider'
import {ProductsColumns} from './columns/_columns'
import {Product} from '../core/_models'
import {ProductsListLoading} from '../components/loading/ProductsListLoading'
import {ProductsListPagination} from '../components/pagination/ProductListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useAuth} from '../../../../auth'
import {searchProduct} from '../core/_requests'

const ProductsTable = () => {
  const users = useQueryResponseData()
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const totalCount = useQueryTotalCount()

  const isLoading = useQueryResponseLoading()
  const [data, setData] = useState(users)
  const columns = useMemo(() => ProductsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  useEffect(() => {
    const filter = async () => {
      if (state.search) {
        console.log('search pr render ho raha hai product')

        const data = await searchProduct(state.search, auth?.access_token)
        if (data.data) {
          setData(data.data)

          console.log('search data', data)
        }
      } else {
        setData(users)
      }
    }
    filter()
  }, [state.search, users])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Product>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Product>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount && totalCount > 10 && <ProductsListPagination />}
      {isLoading && <ProductsListLoading />}
    </KTCardBody>
  )
}

export {ProductsTable}
