import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {User, UsersQueryResponse, filter} from './_models'
import {User as TechnitionData} from '../../../../../apps/employee-management/employes-list/core/_models'
const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_SERVICES_URL = `${API_URL}/service`
const GET_EMPLOYEE_URL = `${API_URL}/employee`
const getServices = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${GET_SERVICES_URL}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const filterUsers = (filter: filter, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(
      `${GET_SERVICES_URL}/filter?status=${filter.status}&startDate=${filter.startDate}&endDate=${filter.endDate}&employee=${filter.employee}&store=undefined`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const searchService = (search: string, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_SERVICES_URL}/search?name=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getAllEmployees = (token: string | undefined): Promise<Array<TechnitionData> | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<TechnitionData>>>) => response.data)
    .then((response: Response<Array<TechnitionData>>) => response.data)
}
export {
  getServices,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  filterUsers,
  searchService,
  getAllEmployees,
}
