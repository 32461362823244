import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const UPDATE_USER_URL = `${API_URL}/employee/update`
const GET_USERS_URL = `${API_URL}/employee`
const getUserById = (id: string, token: string | undefined): Promise<User | undefined> => {
  return axios
    .get(`${GET_USERS_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (
  id: string | undefined,
  token: string | undefined,
  data: User
): Promise<User | undefined> => {
  return axios
    .put(`${UPDATE_USER_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

export {getUserById, updateUser}
