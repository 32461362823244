import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user?: User
}

const ServiceLastLoginCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.allocatedTechnication ? (
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <a href='#'>
            {user.allocatedTechnication?.profilePicture ? (
              <div className='symbol-label'>
                <img
                  src={user.allocatedTechnication?.profilePicture}
                  alt={user.allocatedTechnication.fullName}
                  className='w-75'
                />
              </div>
            ) : (
              <div className='symbol-label'>
                <img
                  src={
                    'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                  }
                  className='w-100'
                />
              </div>
            )}
          </a>
        </div>
        <div className='d-flex flex-column'>
          <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {user.allocatedTechnication?.fullName}
          </a>
          <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {user.allocatedTechnication?.phone}
          </a>
        </div>
      </div>
    ) : (
      <div>not allocated</div>
    )}
  </div>
)

export {ServiceLastLoginCell}
