// @ts-nocheck
import {Column} from 'react-table'
import {ServiceInfoCell} from './ServiceInfoCell'
import {ServiceLastLoginCell} from './ServiceLastLoginCell'
import {ServiceTwoStepsCell} from './ServiceTwoStepsCell'
import {ServiceActionsCell} from './ServiceActionsCell'
import {ServiceSelectionCell} from './ServiceSelectionCell'
import {ServiceCustomHeader} from './ServiceCustomHeader'
import {ServiceSelectionHeader} from './ServiceSelectionHeader'
import {User} from '../../core/_models'
import {CustomerInfoCell} from './CustomerInfoCell'
import {AllocatedBranch} from './AllocatedBranch'
import {ServiceCreatedAt} from './ServiceCreatedAt'
import {ServiceEndedAt} from './ServiceEndedAt'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <ServiceSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ServiceSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Service details' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ServiceInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Customer details' className='min-w-125px' />
    ),
    id: 'customerDetails',
    Cell: ({...props}) => <CustomerInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Allocated To' className='min-w-125px' />
    ),
    id: 'allocated_to',
    Cell: ({...props}) => <ServiceLastLoginCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='branch' className='min-w-125px' />
    ),
    id: 'allocated_branch',
    Cell: ({...props}) => <AllocatedBranch user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <ServiceCreatedAt createdAt={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Ended At' className='min-w-125px' />
    ),
    id: 'ended_at',
    Cell: ({...props}) => <ServiceEndedAt updatedAt={props.data[props.row.index].updatedAt} />,
  },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ServiceTwoStepsCell two_steps={props.data[props.row.index].status} />,
  },

  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ServiceActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
