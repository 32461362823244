import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useCreateUser} from '../../core/QueryResponseProvider'
import toast from 'react-hot-toast'
import {uploadImageOnS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../auth'
import {Store} from '../../core/_models'
import {createUser, getStores} from '../../core/_request'
const profileDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('First name is required'),
  phone: Yup.string().required('Contact phone is required'),
  additionalPhoneNumber: Yup.string(),
  pincode: Yup.string().required('pincode is required'),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  locality: Yup.string().required('locality is required'),
  landmark: Yup.string(),
  email: Yup.string(),
  nearByBranch: Yup.string().required('nearByBranch is required'),
  customerType: Yup.string().required('customerType is required'),
})

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const {mutate, isError, data: newMutateData} = useCreateUser()
  const [stores, setStores] = useState<Store[]>()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0]

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          console.log(selectedFile)
          const data = await uploadImageOnS3({file: selectedFile})
          setProfilePicture(data)

          toast.success('image upload successfully', {id: '30'})

          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  useEffect(() => {
    const fetchStores = async () => {
      const data = await getStores(auth?.access_token)
      setStores(data?.data)
    }
    fetchStores()
  }, [])
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log(values)

      try {
        setLoading(true)
        if (profilePicture) {
          values.profilePicture = profilePicture
          console.log(profilePicture)
        }
        console.log(values.profilePicture)

        const mutateData = await createUser(values, auth?.access_token)
        if (mutateData) {
          console.log('this is the nutate data in the user', mutateData, newMutateData)

          toast.success('User created successfully')
          setLoading(false)
          setData(initialValues)
          setTimeout(() => {
            navigate('/apps/user-management/users')
          }, 2000)
        }
      } catch (error) {
        console.log(error)

        setLoading(false)
        toast.error('Phone number is duplicate')
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add User</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>User image</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {profilePicture ? (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${profilePicture})`}}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7d5X8sAULJnGfVv1CTjnF5i24TBJIevPR9f_etm7Nbw&s)`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Full Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='name'
                    {...formik.getFieldProps('fullName')}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Phone</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone'
                    {...formik.getFieldProps('phone')}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Additional phone number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Additional phone number'
                    {...formik.getFieldProps('additionalPhoneNumber')}
                  />
                  {formik.touched.additionalPhoneNumber && formik.errors.additionalPhoneNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.additionalPhoneNumber}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Pincode</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Pincode'
                    {...formik.getFieldProps('pincode')}
                  />
                  {formik.touched.pincode && formik.errors.pincode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.pincode}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Country</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Country'
                    {...formik.getFieldProps('country')}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.country}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>State</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='State'
                    {...formik.getFieldProps('state')}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.state}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>City</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='City'
                    {...formik.getFieldProps('city')}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.city}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Locality</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Locality'
                    {...formik.getFieldProps('locality')}
                  />
                  {formik.touched.locality && formik.errors.locality && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.locality}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Landmark</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Country'
                    {...formik.getFieldProps('landmark')}
                  />
                  {formik.touched.landmark && formik.errors.landmark && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.landmark}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Customer address</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Customer address'
                    {...formik.getFieldProps('customerAddress')}
                  />
                  {formik.touched.customerAddress && formik.errors.customerAddress && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.customerAddress}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Email id</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email id'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Near by Branch</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('nearByBranch')}
                  >
                    <option value={''}>--select option--</option>
                    {stores &&
                      stores?.map((store) => {
                        return (
                          <option key={store._id} value={store._id}>
                            {`${store.storeName} - ${store.storeState}`}
                          </option>
                        )
                      })}
                  </select>
                  {formik.touched.nearByBranch && formik.errors.nearByBranch && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.nearByBranch}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Customer type</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('customerType')}
                  >
                    <option value=''>--select type--</option>
                    <option value='normal'>Normal</option>
                    <option value='Vip'>vip</option>
                  </select>
                  {formik.touched.customerType && formik.errors.customerType && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.customerType}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add User'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
