import React from 'react'
import './image/styling/main.css'
import prestigeLogo from './image/Prestige-Xclusive-Logo-PNG.png'
import {Service} from '../core/_models'
import moment from 'moment'
type GeneratePdfProps = {
  service: Service
  name: string
}
const GenerateBill: React.FC<GeneratePdfProps> = ({service, name}) => {
  console.log(service)

  return (
    <div>
      {/* <div>{name.toUpperCase()}</div> */}
      <table style={{width: '400px', height: '200px'}}>
        <tr>
          <td colSpan={11} className='heading text-align-center'>
            Job Sheet
          </td>
          <th colSpan={1} rowSpan={2}></th>
        </tr>
        <tr>
          <td colSpan={9} className='heading text-align-center'>
            Customer Care
          </td>
        </tr>
        <tr>
          <td colSpan={12} className='fontWeight-color text-align-center'>
            {service.store
              ? `${service.store?.storeName}, ${service.store?.storeCity}, ${service.store?.storePincode}`
              : '--'}
          </td>
        </tr>
        <tr>
          <td colSpan={12} className='fontWeight-color text-align-center'>
            {service.store ? (
              <span>{`${service.store?.storeCode}, Cell:9885431808/ 9052604111 , Prestige Care: 1800-123-334411`}</span>
            ) : (
              '--'
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={7} className='fontWeight-color'>
            Online Complaint No <span className='text-black'>{service.TTKPLComplaintsNumber}</span>
          </td>
          <td colSpan={2} className='fontWeight-color'>
            Date{' '}
            <span className='text-black'>{moment(service.createdAt).format('DD/MM/YYYY')}</span>
          </td>
          <td colSpan={3} className='fontWeight-color'>
            Job Sheet No.{' '}
            {service.store?.storeCode && (
              <span className='font-size-12 text-black font-weight-400'>{`SSA/23-${moment(
                Date.now()
              ).format('YY')}/${
                service.store?.storeCode?.split('/')[
                  service.store?.storeCode?.split('/').length - 1
                ]
              }-${
                service.storeCentricId &&
                service.storeCentricId?.split('/')[service.storeCentricId?.split('/').length - 1]
              }`}</span>
            )}
          </td>
          {/* <td colSpan={2} className='fontWeight-color'>
          Time in.
        </td> */}
        </tr>
        <tr>
          <td colSpan={7} rowSpan={8} className='fontWeight-color '>
            Customer Details
            <div className='fontWeight-color'>
              <span className='text-black'>{service.user?.fullName}</span>
            </div>
            <div className='fontWeight-color'>
              <span className='text-black'>{service.user?.phone}</span>
            </div>
            <div className='fontWeight-color width-120'>
              <span className='text-black'>{service.user?.city ? service.user?.city : ''}</span>
            </div>
          </td>
          <td colSpan={1} className='fontWeight-color'>
            Product
          </td>
          <td colSpan={6} className='font-size-12'>
            {service.product?.productName}
          </td>
        </tr>

        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Payment
          </td>
          <td className='font-size-12' colSpan={6}>
            {`Rs ${service.finalPricing}`}
          </td>
        </tr>
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Product Code
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.product?.productCode}
          </td>
        </tr>
        {/* <tr>
          <td colSpan={1} className='fontWeight-color'>
            D.O.P
          </td>
          <td colSpan={6}></td>
        </tr> */}
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Product Condition
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.productCondition
              ? service.productCondition[0].toUpperCase() + service.productCondition.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Nature of Complaint
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.natureOfComplaints
              ? service.natureOfComplaints[0].toUpperCase() +
                service.natureOfComplaints.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Repair Type
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.repairType
              ? service.repairType[0].toUpperCase() + service.repairType.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Repair Class
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.repairClass
              ? service.repairClass[0].toUpperCase() + service.repairClass.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={1} className='fontWeight-color'>
            Special Instruction by Customer
          </td>
          <td colSpan={6} className='font-size-12'>
            {service.specialInstructionByCustomer
              ? service.specialInstructionByCustomer[0].toUpperCase() +
                service.specialInstructionByCustomer.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className='fontWeight-color'>
            Nature of Complaint
          </td>
          <td className='font-size-12' colSpan={6}>
            {service.natureOfComplaint
              ? service.natureOfComplaint[0].toUpperCase() + service.natureOfComplaint.substring(1)
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={7} rowSpan={2} className='fontWeight-color'>
            Authorised Signature
          </td>
          <td colSpan={5} rowSpan={2} className='fontWeight-color'>
            Customer Signature
          </td>
        </tr>
      </table>
    </div>
  )
}

export default GenerateBill
