import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ServicesListFilter} from './ServicesListFilter'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {generateBillInPdf} from '../../../../../../../_metronic/helpers/crud-helper/generatePdfHelper'
import {serviceDetail} from '../../../../../../../_metronic/helpers/constants'
import {User} from '../../core/_models'

const ServicesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const users = useQueryResponseData()

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const generatePdf = () => {
    let ser = localStorage.getItem(serviceDetail)
    if (!ser) {
      console.log('this is the ser', ser)
      generateBillInPdf(users, 'Service')
    } else {
      let jsonSer: User[] = JSON.parse(ser)
      generateBillInPdf(jsonSer, 'Service')
    }
  }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ServicesListFilter />

      {/* begin::Export */}
      <button onClick={() => generatePdf()} type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <Link to={`/apps/add-service/settings`} type='button' className='btn btn-primary'>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Services
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export {ServicesListToolbar}
