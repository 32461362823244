import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'

import {EmployesListHeader} from './components/header/EmployesListHeader'
import {EmployesTable} from './table/EmployesTable'

import {KTCard} from '../../../../../../_metronic/helpers'

const EmployesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        {/* <EmployesListHeader /> */}
        <EmployesTable />
      </KTCard>
    </>
  )
}

const EmployesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmployesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EmployesListWrapper}
