import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {TranscationsColumns} from './columns/_columns'
import {User} from '../core/_models'
import {TranscationsListLoading} from '../components/loading/TranscationsListLoading'
import {TranscationsListPagination} from '../components/pagination/TranscationsPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {filterService} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {transactionDetail} from '../../../../../../_metronic/helpers/constants'

const TranscationsTable = () => {
  const users = useQueryResponseData()
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users, [users])
  const [data, setData] = useState(users)
  const columns = useMemo(() => TranscationsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const dataWithPricing = (d: User[]) => {
    if (d.length > 0) {
      let newData = d.filter((item) => {
        if (item.finalPricing) {
          console.log('final pricing', item.finalPricing)

          return +item.finalPricing > 0
        }
      })
      console.log('this is the new data', newData)

      return newData
    }
    return []
  }
  useEffect(() => {
    try {
      const filterTransactionData = async () => {
        if (state.filter) {
          const filterData = await filterService(state.filter, auth?.access_token)
          console.log('data change in the transactions', state.filter)
          if (filterData.data) {
            setData(dataWithPricing(filterData.data))
            localStorage.setItem(
              transactionDetail,
              JSON.stringify(dataWithPricing(filterData.data))
            )
          }
          console.log('this is the transactions filter data', filterData)
        } else {
          setData(users)
        }
      }
      filterTransactionData()
    } catch (error) {}
  }, [state.filter, users])
  useEffect(() => {
    setData(dataWithPricing(users))
  }, [users])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody id='kt_table_users' className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TranscationsListPagination />
      {isLoading && <TranscationsListLoading />}
    </KTCardBody>
  )
}

export {TranscationsTable}
