import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Store, User, UsersQueryResponse, filter} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/employee`
const GET_STORES_URL = `${API_URL}/store`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const filterUsers = (filter: filter, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}/filter?designation=${filter.designation}&branch=${filter.branch}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const searchEmployee = (search: string, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}/search?name=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${GET_USERS_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${GET_USERS_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getAllStores = (token: string | undefined): Promise<Array<Store> | undefined> => {
  return axios
    .get(`${GET_STORES_URL}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<Array<Store>>>) => response.data)
    .then((response: Response<Array<Store>>) => response.data)
}
export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  searchEmployee,
  getUserById,
  createUser,
  updateUser,
  filterUsers,
  getAllStores,
}
