import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Store, StoresQueryResponse, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const EMPLOYEE_URL = `${API_URL}/employee/register`
const GET_ALL_STORES = `${API_URL}/store`

const createEmployee = (user: User): Promise<User | undefined> => {
  return (
    axios
      .post(EMPLOYEE_URL, user)
      // .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  )
}
const getStores = (token: string | undefined): Promise<StoresQueryResponse | undefined> => {
  return axios
    .get(`${GET_ALL_STORES}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<StoresQueryResponse>) => d.data)
}
export {createEmployee, getStores}
