import {FC} from 'react'

type Props = {
  code?: string
  sku?: string
}

const ProductCode: FC<Props> = ({code}) => <div className='fw-bolder'>{code}</div>

export {ProductCode}
