import {useListView} from '../../core/ListViewProvider'
import {TranscationsListToolbar} from './TranscationsListToolbar'
import {TranscationsListGrouping} from './TranscationsListGrouping'
import {TranscationsListSearchComponent} from './TranscationsListSearchComponent'

const TranscationsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 d-flex justify-content-end'>
      {/* <TranscationsListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TranscationsListGrouping /> : <TranscationsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TranscationsListHeader}
