import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useGetServiceById, useUpdateServiceEmployeeById} from '../../core/QueryResponseProvider'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import toast from 'react-hot-toast'

const profileDetailsSchema = Yup.object().shape({
  natureOfComplaint: Yup.array().of(Yup.string()).nullable(),
  TTKPLComplaintsNumber: Yup.string().nullable(),
  // .min(1, 'Select at least one nature of complaint')
  // .required('Nature of complaint is required'),
  repairType: Yup.array().of(Yup.string()).nullable(),
  // .min(1, 'Select at least one repair type')
  // .required('Repair type is required'),
  productCondition: Yup.string().nullable(),
  repairClass: Yup.array().of(Yup.string()).nullable(),
  // .min(1, 'Select at least one repair class')
  // .required('Repair class is required'),
  specialInstructionByCustomer: Yup.string(),
  natureOfComplaints: Yup.string().nullable(),
  repairTypeOther: Yup.string().nullable(),
  // startDate: Yup.string().nullable().default(undefined),
  // endDate: Yup.string().nullable().default(undefined),
})

const ProfileDetails: React.FC = () => {
  const {id} = useParams()
  const {mutate} = useUpdateServiceEmployeeById(id)
  const {data: service, isFetching, refetch} = useGetServiceById(id)

  const navigate = useNavigate()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [productReviewOther, setProductReviewOther] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
      service?.repairType !== 'Customer education' &&
      service?.repairType !== 'Adjustment' &&
      service?.repairType !== 'Part replacement'
    ) {
      setProductReviewOther(true)
    }
  }, [])
  const formik = useFormik<IProfileDetails>({
    initialValues: service ? service : initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log('this is the service detail value', values)

      const mutateData = {
        natureOfComplaint: values?.natureOfComplaint && values?.natureOfComplaint,
        repairType: values?.repairType && values?.repairType,
        productCondition: values.productCondition,
        repairClass: values?.repairClass && values?.repairClass,
        specialInstructionByCustomer: values.specialInstructionByCustomer,
        natureOfComplaints: values.natureOfComplaints,
        TTKPLComplaintsNumber: values.TTKPLComplaintsNumber,
        startDate: values.startDate,
        endDate: values.endDate,
      }
      try {
        await mutate(mutateData)
        toast.success('Service update successfully')
        console.log(window.location.href)

        // navigate(`/app/service-detail/${id}/overview`)
        navigate(-1)
        refetch()
        setLoading(false)
      } catch (error) {
        toast.error('Something went wrong')

        setLoading(false)
      }
    },
    enableReinitialize: true,
  })
  console.log(formik.errors)

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Service review</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                    ></div>
                  </div>
                </div>
              </div> */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>TTKPL Number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='TTKPL complaints number'
                    {...formik.getFieldProps('TTKPLComplaintsNumber')}
                  />
                  {formik.touched.TTKPLComplaintsNumber && formik.errors.TTKPLComplaintsNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.TTKPLComplaintsNumber}</div>
                    </div>
                  )}
                </div>
              </div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nature of Complaint</span>
                </label>

                <div className='d-flex gap-5 fv-row'>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Installation'
                      name='natureOfComplaint'
                      id='reverseCheck1'
                      defaultChecked={service?.natureOfComplaint === 'Installation'}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Installation
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Warranty'
                      name='natureOfComplaint'
                      id='reverseCheck1'
                      defaultChecked={service?.natureOfComplaint === 'Warranty'}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Warranty
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Out of warranty'
                      id='reverseCheck1'
                      name='natureOfComplaint'
                      onChange={formik.handleChange}
                      defaultChecked={service?.natureOfComplaint === 'Out of warranty'}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck1'>
                      Out of warranty
                    </label>
                  </div>
                </div>
              </div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Repair type</span>
                </label>

                <div>
                  <div className='d-flex gap-5 fv-row'>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Part replacement'
                        name='repairType'
                        id='reverseCheck13'
                        // defaultChecked={formik.values.repairType.partReplacement}
                        defaultChecked={service?.repairType === 'Part replacement'}
                        onChange={formik.handleChange}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Part replacement
                      </label>
                    </div>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Adjustment'
                        id='reverseCheck13'
                        name='repairType'
                        // defaultChecked={formik.values.repairType.adjustment}
                        onChange={formik.handleChange}
                        defaultChecked={service?.repairType === 'Adjustment'}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Adjustment
                      </label>
                    </div>

                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Customer education'
                        id='reverseCheck13'
                        name='repairType'
                        // defaultChecked={formik.values.repairType.customerEducation}
                        defaultChecked={service?.repairType === 'Customer education'}
                        onChange={formik.handleChange}
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Customer education
                      </label>
                    </div>
                    <div className='form-check form-check-reverse'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='others'
                        id='reverseCheck13'
                        name='repairType'
                        onClick={() => setProductReviewOther((state) => !state)}
                        defaultChecked={
                          service?.repairType !== 'Customer education' &&
                          service?.repairType !== 'Adjustment' &&
                          service?.repairType !== 'Part replacement'
                        }
                      />
                      <label className='form-check-label' htmlFor='reverseCheck13'>
                        Others
                      </label>
                    </div>
                  </div>
                  {productReviewOther && (
                    <div className='col-lg-8 mt-5 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='others'
                        {...formik.getFieldProps('repairType')}
                      />
                      {formik.touched.repairType && formik?.errors?.repairType && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.repairType}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Product condition</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Product condition'
                    {...formik.getFieldProps('productCondition')}
                  />
                  {formik.touched.productCondition && formik.errors.productCondition && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.productCondition}</div>
                    </div>
                  )}
                </div>
              </div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='d-flex align-items-center mb-6'
              >
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Repair class</span>
                </label>

                <div className='d-flex gap-5 fv-row'>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='Service center workshop'
                      id='reverseCheck12'
                      defaultChecked={service?.repairClass === 'Service center workshop'}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Service center workshop
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='filed'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                      defaultChecked={service?.repairClass === 'filed'}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Filed
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='dealer'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                      defaultChecked={service?.repairClass === 'dealer'}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Dealer
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='showrroom'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                      defaultChecked={service?.repairClass === 'showrroom'}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      Showrroom
                    </label>
                  </div>
                  <div className='form-check form-check-reverse'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='repairClass'
                      value='PSK'
                      id='reverseCheck12'
                      onChange={formik.handleChange}
                      defaultChecked={service?.repairClass === 'PSK'}
                    />
                    <label className='form-check-label' htmlFor='reverseCheck12'>
                      PSK
                    </label>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Special instructions by the customer</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Special instructions by the customer'
                    {...formik.getFieldProps('specialInstructionByCustomer')}
                  />
                  {formik.touched.specialInstructionByCustomer &&
                    formik.errors.specialInstructionByCustomer && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.specialInstructionByCustomer}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nature of complaint</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nature of complaint'
                    {...formik.getFieldProps('natureOfComplaints')}
                  />
                  {formik.touched.natureOfComplaints && formik.errors.natureOfComplaints && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.natureOfComplaints}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Start Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Start Date'
                    {...formik.getFieldProps('startDate')}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.startDate}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>End Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='End Date'
                    {...formik.getFieldProps('endDate')}
                  />
                  {formik.touched.endDate && formik.errors.endDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.endDate}</div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary'>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {ProfileDetails}
{
  /* <div className='card mb-5 mb-xl-10'>
  <div
    className='card-header border-0 cursor-pointer'
    role='button'
    data-bs-toggle='collapse'
    data-bs-target='#kt_account_profile_details'
    aria-expanded='true'
    aria-controls='kt_account_profile_details'
  >
    <div className='card-title m-0'>
      <h3 className='fw-bolder m-0'>Customer details</h3>
    </div>
  </div>

  <div id='kt_account_profile_details' className='collapse show'>
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card-body border-top p-9'>
        {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                    ></div>
                  </div>
                </div>
              </div> */
}

// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full name</label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Full name'
//       {...formik.getFieldProps('storeName')}
//     />
//     {formik.touched.storeName && formik.errors.storeName && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeName}</div>
//       </div>
//     )}
//   </div>
// </div>

// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Phone number</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='tel'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Phone number'
//       {...formik.getFieldProps('storePhoneNumber')}
//     />
//     {formik.touched.storePhoneNumber && formik.errors.storePhoneNumber && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storePhoneNumber}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Additional phone number</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='tel'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Additional phone number'
//       {...formik.getFieldProps('storePhoneNumber')}
//     />
//     {formik.touched.storePhoneNumber && formik.errors.storePhoneNumber && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storePhoneNumber}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Pincode</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Pincode'
//       {...formik.getFieldProps('storePincode')}
//     />
//     {formik.touched.storePindoce && formik.errors.storePindoce && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storePindoce}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Country</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Country'
//       {...formik.getFieldProps('storeCpuntry')}
//     />
//     {formik.touched.storeCountry && formik.errors.storeCountry && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeCountry}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>State</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='State'
//       {...formik.getFieldProps('storeState')}
//     />
//     {formik.touched.storeState && formik.errors.storeState && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeState}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>City</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='City'
//       {...formik.getFieldProps('storeCity')}
//     />
//     {formik.touched.storeCity && formik.errors.storeCity && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeCity}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Locality</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Locality'
//       {...formik.getFieldProps('storeLocality')}
//     />
//     {formik.touched.storeLocality && formik.errors.storeLocality && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeLocality}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Landmark</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='text'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Landmark'
//       {...formik.getFieldProps('storeLandmark')}
//     />
//     {formik.touched.storeLandmark && formik.errors.storeLandmark && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeLandmark}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Email id</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <input
//       type='email'
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Store Email id'
//       {...formik.getFieldProps('storeEmailId')}
//     />
//     {formik.touched.storeEmailId && formik.errors.storeEmailId && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeEmailId}</div>
//       </div>
//     )}
//   </div>
// </div>
// <div className='row mb-6'>
//   <label className='col-lg-4 col-form-label fw-bold fs-6'>
//     <span className='required'>Address</span>
//   </label>

//   <div className='col-lg-8 fv-row'>
//     <textarea
//       className='form-control form-control-lg form-control-solid'
//       placeholder='Address'
//       {...formik.getFieldProps('storeEmailId')}
//     />
//     {formik.touched.storeEmailId && formik.errors.storeEmailId && (
//       <div className='fv-plugins-message-container'>
//         <div className='fv-help-block'>{formik.errors.storeEmailId}</div>
//       </div>
//     )}
//   </div>
// </div>
// </div>

// <div className='card-footer d-flex justify-content-end py-6 px-9'>
//   <button type='submit' className='btn btn-primary' disabled={loading}>
//     {!loading && 'Save Changes'}
//     {loading && (
//       <span className='indicator-progress' style={{display: 'block'}}>
//         Please wait...{' '}
//         <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//       </span>
//     )}
//   </button>
// </div>
// </form>
// </div>
// </div>
