import {useEffect, useMemo, useState} from 'react'
import {useQueryResponse} from '../../../modules/apps/employee-management/employes-list/core/QueryResponseProvider'
import {useQueryRequest} from '../../../modules/apps/employee-management/employes-list/core/QueryRequestProvider'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import Calendar from 'react-calendar'
import {DateRangePicker, RangeKeyDict} from 'react-date-range'
import moment from 'moment'
import {useStartAndEndDateProvider} from './core/context/Provider'
type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]
const CalendarFilter = () => {
  const {updateState} = useQueryRequest()
  const {updateStartEndDate} = useStartAndEndDateProvider()
  const {isLoading} = useQueryResponse()
  const store = null
  const employee = null
  const [startDate, setStartDate] = useState<string | null | undefined>()
  const [endDate, setEndDate] = useState<string | null>()

  const [dateState, setDateState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setStartDate(null)
    setEndDate(null)
    setDateState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    })
  }

  const filterData = () => {
    updateStartEndDate(startDate, endDate, store, employee)
  }
  const handleSelect = (rangesByKey: RangeKeyDict) => {
    if (
      rangesByKey.selection.startDate &&
      rangesByKey.selection.endDate &&
      rangesByKey.selection.key
    ) {
      setDateState({
        startDate: rangesByKey.selection.startDate,
        endDate: rangesByKey.selection.endDate,
        key: rangesByKey.selection.key,
      })
    }
    const selectedStartDate = moment(rangesByKey.selection.startDate).format('YYYY-MM-DD')
    const selectedEndDate = moment(rangesByKey.selection.endDate).format('YYYY-MM-DD')
    setStartDate(selectedStartDate)
    setEndDate(selectedEndDate)

    console.log(selectedStartDate, selectedEndDate)
  }
  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Calendar
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown w-300px h-300px overflow-scroll w-md-50'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <DateRangePicker ranges={[dateState]} onChange={handleSelect} />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div> */}
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={() => resetData()}
              disabled={isLoading}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              onClick={() => filterData()}
              className='btn btn-primary fw-bold px-6'
              // data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export default CalendarFilter

// date range filter inthe revenue
// employee
// store in the revenue

// complaints
// exiort in the pdf and the ecexl format
// staus
// store
// employee
// date range filter
