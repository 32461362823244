import React, {FC, createContext, useContext, useState} from 'react'
import {Product, User} from '../core/_models'

// Define the DefaultValues interface
interface DefaultValues {
  user?: User | null
  product?: Product | null
  addProduct: (product: Product) => void
  addUser: (user: User) => void
}

const defaultValues: DefaultValues = {
  user: null,
  product: null,
  addProduct: () => {},
  addUser: () => {},
}

// Create the context
const ServiceUserAndProductContext = createContext<DefaultValues>(defaultValues)
interface ServiceUserAndProductProviderProps {
  children: React.ReactNode // Define the type for 'children'
}
// Create a Context Provider component
const ServiceUserAndProductProvider: FC<ServiceUserAndProductProviderProps> = ({children}) => {
  // Define default values for user and product
  const [user, setUser] = useState<User | null>(null)
  const [product, setProduct] = useState<Product | null>(null)

  const addProduct = (newProduct: Product) => {
    setProduct(newProduct)
  }

  const addUser = (newUser: User) => {
    setUser(newUser)
  }
  const data: DefaultValues = {
    user,
    product,
    addUser,
    addProduct,
  }
  return (
    <ServiceUserAndProductContext.Provider value={data}>
      {children}
    </ServiceUserAndProductContext.Provider>
  )
}

export {ServiceUserAndProductContext, ServiceUserAndProductProvider}
