/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
type Props = {
  user: User
}

const date = Date.now()
const AllocatedBranch: FC<Props> = ({user}) => {
  // const users = useQueryResponseData()
  // function generatePDF(users: User[]) {
  //   const doc = new jsPDF()

  //   const tableColumn = Object.keys(users[0]) // Extract column headers from the first object in the JSON data
  //   const tableRows = users.map((obj) => Object.values(obj)) // Extract values from each object in the JSON data

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //   })

  //   doc.save('output.pdf')
  // }

  return (
    <>
      {' '}
      {user?.allocatedTechnication?.branch ? (
        <div className='fw-'>{user?.allocatedTechnication?.branch?.storeCode}</div>
      ) : (
        <div className=''>No allocated</div>
      )}
    </>
  )
}

export {AllocatedBranch}
