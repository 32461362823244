interface images {
  Location: string
  _id?: string
}
export interface IProductDetails {
  _id?: string
  productName?: String
  productDescription?: String
  createdAt?: String
  productTax?: String
  is_deactivated?: Boolean
  productPrice?: string
  productCode?: string
  productId?: string
  productPicture?: Array<images>
}
export type Store = {
  _id?: string
  storeName?: string
  storePhoneNumber?: string
  storeEmailId?: string
  storePincode?: string
  storeCountry?: string
  storeState?: string
  storeCity?: string
  is_deactivated?: Boolean
  storePicture?: string
  storeLocality?: string
  storeLandMark?: string
  storeCode?: string
  createdAt?: string
  updatedAt?: string
}
export interface IServiceDetails {
  natureOfComplain: string
  repairType: string
  productCondition?: string | undefined
  repairClass: string
  specialInstructionByCustomer?: string | undefined
  natureOfComplaints?: string | undefined
  TTKPLComplaintsNumber?: string | undefined
  repairTypeOther?: string | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  store?: string | undefined
}
export interface IUserDetails {
  _id?: string
  profilePicture?: string
  fullName?: string
  phone?: string
  additionalPhoneNumber?: string
  pincode?: string
  country?: string
  state?: string
  city?: string
  locality?: string
  landmark?: string
  customerAddress?: string
  email?: string
  nearByBranch?: string
  customerType?: string
}
export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const userDetailsInitValues: IUserDetails = {
  profilePicture: '',
  fullName: '',
  phone: '',
  additionalPhoneNumber: '',
  pincode: '',
  country: '',
  state: '',
  city: '',
  locality: '',
  landmark: '',
  customerAddress: '',
  email: '',
  nearByBranch: '',
  customerType: '',
}
export const serviceDetailsInitValues: IServiceDetails = {
  natureOfComplain: '',
  repairType: '',
  productCondition: '',
  repairClass: '',
  specialInstructionByCustomer: '',
  natureOfComplaints: '',
}
export const ProductDetailsInitValues: IProductDetails = {
  _id: '',
  productName: '',
  productCode: '',
  productTax: '',
  productDescription: '',
  is_deactivated: false,
  productPrice: '',
  productPicture: [],
}
export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
