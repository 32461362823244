import moment from 'moment'
import {FC} from 'react'

type Props = {
  createdAt?: string
}

const ServiceCreatedAt: FC<Props> = ({createdAt}) => (
  <>
    {createdAt ? (
      <div className='fw-bolder'>{moment(createdAt).format('DD-MM-YYYY')}</div>
    ) : (
      <div className='fw-bolder'>Not select</div>
    )}
  </>
)

export {ServiceCreatedAt}
