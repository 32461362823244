import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createEmployee, getStores} from '../../core/_request'
import {User, Store, StoresQueryResponse} from '../../core/_models'
import {Toast} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {uploadImageOnS3} from '../../../../../../_metronic/helpers/uitils/functions'
import {useAuth} from '../../../../auth'
import {useNavigate} from 'react-router-dom'
interface Error {
  error: string
  err: {
    index: number
    code: number
    keyPattern: {
      phone: number
    }
    keyValue: {
      phone: string
    }
  }
}
const profileDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('First name is required'),
  profilePicture: Yup.string(),
  phone: Yup.string().required('Contact phone is required'),
  email: Yup.string().required('email is required'),
  branch: Yup.string().required('branch is required'),
  designation: Yup.string().required('designation is required'),
  password: Yup.string().required('Password is required'),
})

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [stores, setStores] = useState<Store[]>()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const handleGetTheImageFromLocal = async () => {
    const fileInput = document.createElement('input')

    // Set the type attribute to 'file' for a file input
    fileInput.setAttribute('type', 'file')

    // Set other attributes and properties as needed
    fileInput.setAttribute('id', 'myFileInput')
    fileInput.setAttribute('name', 'myFile')
    fileInput.setAttribute('accept', '.jpg, .jpeg, .png')

    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0]

      if (selectedFile) {
        setImageUploadLoading(true)
        toast.loading('image upload please wait', {id: '30'})
        try {
          console.log(selectedFile)
          const data = await uploadImageOnS3({file: selectedFile})
          setProfilePicture(data)

          toast.success('image upload successfully', {id: '30'})
          setImageUploadLoading(false)
        } catch (error) {
          setImageUploadLoading(false)
          toast.error('Something went wrong', {id: '30'})
        }
      }
    })

    fileInput.click()
  }
  useEffect(() => {
    const fetchStores = async () => {
      const data = await getStores(auth?.access_token)
      setStores(data?.data)
    }
    fetchStores()
  }, [])
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      console.log(values)
      if (profilePicture) {
        values.profilePicture = profilePicture
        console.log(profilePicture)
      }
      try {
        const data: User | undefined = await createEmployee(values)
        console.log(data)

        if (data) {
          toast.success('Employee add successfully.')
          setLoading(false)
          setData(initialValues)
          setTimeout(() => {
            navigate('/apps/employe-management/employes')
          }, 2000)
        } else {
          throw new Error('Phone number already exists')
        }
      } catch (error: any) {
        setLoading(false)
        console.log('employee', error)

        toast.error(`${error.message}. phone number or email is already exist`)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Employee</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Employee image</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {profilePicture ? (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${profilePicture})`}}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7d5X8sAULJnGfVv1CTjnF5i24TBJIevPR9f_etm7Nbw&s)`,
                      }}
                      onClick={() => handleGetTheImageFromLocal()}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8 fv-row'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('fullName')}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.fullName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Email id</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email id'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Branch</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('branch')}
                >
                  <option value={''}>--select option--</option>
                  {stores &&
                    stores?.map((store) => {
                      return (
                        <option key={store._id} value={store._id}>
                          {`${store.storeName} - ${store.storeState}`}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.branch && formik.errors.branch && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.branch}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Designation</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Designation'
                  {...formik.getFieldProps('designation')}
                />
                {formik.touched.designation && formik.errors.designation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.designation}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add Employee'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
