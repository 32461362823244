/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Section one</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {currentUser && currentUser.user.is_admin ? (
        <SidebarMenuItem
          to='/crafted/adminaccount/overview'
          icon='/media/icons/duotune/art/art002.svg'
          title='Admin profile'
          fontIcon='bi-app-indicator'
        />
      ) : (
        <SidebarMenuItem
          to='/crafted/adminaccount/overview'
          icon='/media/icons/duotune/art/art002.svg'
          title='Employee profile'
          fontIcon='bi-app-indicator'
        />
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Section two</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/service-management/services'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Service management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/transaction-management/transactions'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Transactions'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/apps/report-management/reports'
        title='Reports'
        fontIcon='/media/icons/duotune/general/gen051.svg'
        icon='/media/icons/duotune/general/gen051.svg'
        children={
          <SidebarMenuItem
            to='/apps/report-management/report/revenues'
            // icon='/media/icons/duotune/general/gen051.svg'
            hasBullet
            title='Revenue'
            fontIcon='bi-layers'
            children={
              <SidebarMenuItem
                to='/apps/report-management/report/complaints'
                // icon='/media/icons/duotune/general/gen051.svg'
                title='Complaints'
                fontIcon='bi-layers'
                hasBullet
              />
            }
          />
        }
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Section three</span>
        </div>
      </div>
      {currentUser && currentUser.user.is_admin && (
        <SidebarMenuItem
          to='/apps/employe-management/employes'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee Management'
          fontIcon='bi-archive'
        />
      )}
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Customer management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/product-management/products'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Product management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/store-management/stores'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Store management'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
