/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {defaultCreateAppData, ICreateAppData} from './IAppModels'
import {StepperComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'
import moment from 'moment'

type Props = {
  show: boolean
  handleClose: () => void
  onSubmit: any
  title: any
  desc: any
  setDesc: any
  setTitle: any
  loading?: boolean
  date?: string
  setDate?: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const NoteModal = ({
  show,
  handleClose,
  onSubmit,
  title,
  desc,
  setDesc,
  setTitle,
  loading,
  date,
  setDate,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Create Note</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <label>Enter title</label>
        <input
          type='text'
          className='form-control mb-4 mt-4 form-control-lg form-control-solid'
          id='title'
          placeholder='Enter title'
          value={title}
          onChange={(e: any) => {
            setTitle(e.target.value)
          }}
        />

        <label>Enter description</label>

        <textarea
          className='form-control mb-4 mt-4 form-control-lg form-control-solid'
          id='title'
          value={desc}
          placeholder='Enter description'
          onChange={(e: any) => {
            setDesc(e.target.value)
          }}
        />

        <label>
          Enter Date {date != '' ? `current date: ${moment(date).format('DD/MM/YYYY')}` : ''}
        </label>

        <input
          type='date'
          className='form-control mb-4 mt-4 form-control-lg form-control-solid'
          // id="title"
          placeholder={date}
          // defaultValue={date}
          onChange={(e: any) => {
            setDate(e.target.value)
          }}
        />

        {loading ? (
          <button
            className='btn btn-primary'
            style={{
              width: '50%',
              marginTop: '15px',
            }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   onSubmit();
            // }}
          >
            Loading...
          </button>
        ) : (
          <button
            className='btn btn-primary'
            style={{
              width: '50%',
              marginTop: '15px',
            }}
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            Submit
          </button>
        )}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {NoteModal}
