/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {useGetStoreById} from './core/QueryResponseProvider'
import {useAuth} from '../../auth'

export function Overview() {
  const {id} = useParams()
  const {data, isFetching} = useGetStoreById(id)
  const {currentUser} = useAuth()
  console.log(data)
  if (data && !isFetching) {
    return (
      <>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Store Details</h3>
            </div>

            {currentUser && currentUser.user.is_admin && (
              <Link
                to={`/apps/store-detail/${id}/settings`}
                className='btn btn-primary align-self-center'
              >
                Edit Store
              </Link>
            )}
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Store name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data.storeName}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Store Email id</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data.storeEmailId}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Store phone number
                <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Phone number must be active'
                ></i>
              </label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{data.storePhoneNumber}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Store location
                <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i>
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{`${data?.storeName}, ${data?.storeState}, ${data?.storeCity}, ${data?.storePincode}, ${data?.storeCountry}`}</span>
              </div>
            </div>

            <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Store status</label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>{data.is_deactivated ? 'inactive' : 'Active'}</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}
      </>
    )
  } else {
    return <div>Please wait.....</div>
  }
}
