import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  branch?: User
}

const TranscationTwoStepsCell: FC<Props> = ({branch}) => (
  <>
    <div className='fw-bolder'>
      {branch?.allocatedTechnication
        ? `${branch?.allocatedTechnication.branch?.storeName}-${branch?.allocatedTechnication.branch?.storeCode}`
        : ''}
    </div>
  </>
)

export {TranscationTwoStepsCell}
