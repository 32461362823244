import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {generateProductPdf} from '../../../../../../../_metronic/helpers/crud-helper/generatePdfHelper'
import {useAuth} from '../../../../../auth'
import {getAllProducts} from '../../core/_requests'
import {useEffect} from 'react'

const ProductsListToolbar = () => {
  const products = useQueryResponseData()
  const handlePdf = () => {
    generateProductPdf(products, 'Product')
  }
  const {auth} = useAuth()
  const getProducts = async () => {
    const data = await getAllProducts(auth?.access_token)
    console.log('get products data', data)
  }
  useEffect(() => {
    getProducts()
  }, [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ProductsListFilter /> */}

      {/* begin::Export */}
      <button onClick={() => handlePdf()} type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <Link to={`/apps/add-product/settings`} type='button' className='btn btn-primary'>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Product
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export {ProductsListToolbar}
