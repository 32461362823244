import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import {AccountHeader} from './AccountHeader'
import {ServiceUserAndProductProvider} from './components/context'

const addServiceBreadCrumbs: Array<PageLink> = [
  {
    title: 'Add service',
    path: '/apps/add-service/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AddSevicePage: React.FC = () => {
  return (
    <ServiceUserAndProductProvider>
      <Routes>
        <Route
          element={
            <>
              {/* <AccountHeader /> */}
              <Outlet />
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={addServiceBreadCrumbs}>Admin profile</PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={addServiceBreadCrumbs}>Add service</PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to='/apps/add-service/overview' />} />
        </Route>
      </Routes>
    </ServiceUserAndProductProvider>
  )
}

export default AddSevicePage
