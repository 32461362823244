import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  finalPricing?: string
}

const ChargesService: FC<Props> = ({finalPricing}) => (
  <>
    <div className='fw-bolder'>{finalPricing ? finalPricing : ''}</div>
  </>
)

export {ChargesService}
