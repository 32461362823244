import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Store, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/store`

const createStore = (store: Store, token: string | undefined): Promise<Store | undefined> => {
  return (
    axios
      .post(`${USER_URL}/register`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        store,
      })
      // .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<Store>) => response.data)
  )
}

export {createStore}
