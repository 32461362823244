import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {TranscationsListWrapper} from './products-list/TranscationsList'
const TranscationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report Revenue',
    path: '/apps/report-management/report/revenues',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportTranscationsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='revenue'
          element={
            <>
              <PageTitle breadcrumbs={TranscationsBreadcrumbs}>Revenue Report Management</PageTitle>
              <TranscationsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/report-management/report/revenues/revenue' />} />
    </Routes>
  )
}

export default ReportTranscationsPage
