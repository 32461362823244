import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
// import {User} from '../../../app/modules/apps/service-management/users-list/core/_models'
import {User} from '../../../../src/app/modules/apps/transcation/products-list/core/_models'
import moment from 'moment'
import {Product} from '../../../app/modules/apps/product-management/products-list/core/_models'
export const generateBillInPdf = (users: User[], title?: string) => {
  if (users.length > 0) {
    // kt_table_users
    const keys = [
      'Product Name',
      'Product Code',
      'Allocated Branch',
      'Allocated Technication',
      'Allocated TechnicationPhone',
      'Status',
      'Start Date',
      'Final Pricing',
      'Repair Type',
      'Product Condition',
      'Repair Class',
      'Nature Of Complaint',
    ]
    const doc = new jsPDF('p', 'mm', [297, 300])
    const tableRows: any = []
    doc.text(`${title && title} Report`, 14, 15)
    // for each ticket pass all its data into an array
    users.forEach((data) => {
      const ticketData = [
        data.product?.productName,
        data.product?.productCode,
        data.allocatedTechnication?.branch?.storeCode,
        data.allocatedTechnication?.fullName,
        data.allocatedTechnication?.phone,
        data.status,
        data.startDate,
        data.finalPricing,
        data.repairType,
        data.productCondition,
        data.repairClass,
        data.natureOfComplaint,
      ]
      // push each tickcet's info into a row
      tableRows.push(ticketData)
    })

    // Or use javascript directly:
    autoTable(doc, {
      head: [keys],
      body: tableRows,
      startY: 20,
    })

    doc.save(`${title && title}-${moment(Date.now()).format('MM/DD/YYYY')}.pdf`)
  }
  return
}

export const generateSingleBillInPdf = (user: User, title?: string) => {
  // kt_table_users
  console.log('hi')
  const keys = [
    'Product Name',
    'Product Code',
    'Allocated Branch',
    'Allocated Technication',
    'Allocated TechnicationPhone',
    'Status',
    'Start Date',
    'Final Pricing',
    'Repair Type',
    'Product Condition',
    'Repair Class',
    'Nature Of Complaint',
  ]
  console.log(keys)
  const doc = new jsPDF('p', 'mm', [297, 300])
  const tableRows: any = []

  // for each ticket pass all its data into an array

  const ticketData = [
    user.product?.productName,
    user.product?.productCode,
    user.allocatedTechnication?.branch?.storeCode,
    user.allocatedTechnication?.fullName,
    user.allocatedTechnication?.phone,
    user.status,
    user.startDate,
    user.finalPricing,
    user.repairType,
    user.productCondition,
    user.repairClass,
    user.natureOfComplaint,
  ]
  // push each tickcet's info into a row
  tableRows.push(ticketData)

  // Or use javascript directly:
  autoTable(doc, {
    head: [keys],
    body: tableRows,
    startY: 20,
  })
  doc.text(`${title && title} Report`, 14, 15)

  doc.save(`${title && title}-${moment(Date.now()).format('MM/DD/YYYY')}.pdf`)
}
export const generateProductPdf = (product: Product[], title?: string) => {
  if (product.length > 0) {
    const keys = [
      'Product Name',
      'Product Code/SKU',
      'Product Price',
      'Product Description',
      'Status',
    ]
    const doc = new jsPDF('p', 'mm', [297, 300])
    const tableRows: any = []
    doc.text(`${title && title} Report`, 14, 15)
    product.forEach((data) => {
      const ticketData = [
        data.productName,
        data.productCode,
        data.productPrice,
        data.productDescription,
        data.is_deactivated ? 'Deactive' : 'Active',
      ]
      // push each tickcet's info into a row
      tableRows.push(ticketData)
    })
    autoTable(doc, {
      head: [keys],
      body: tableRows,
      startY: 20,
    })

    doc.save(`${title && title}-${moment(Date.now()).format('MM/DD/YYYY')}.pdf`)
  } else {
    return
  }
}
