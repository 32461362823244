import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ServicesListHeader} from './components/header/ServicesListHeader'
import {ServicesTable} from './table/ServicesTable'

import {KTCard} from '../../../../../_metronic/helpers'

const ServicesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ServicesListHeader />
        <ServicesTable />
      </KTCard>
    </>
  )
}

const ServicesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ServicesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ServicesListWrapper}
