import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Product, UsersQueryResponse} from './_models'
import {useAuth} from '../../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PRODUCT_URL = `${API_URL}/product`
const GET_PRODUCT_URL = `${API_URL}/product`

const getProducts = (token: string | undefined, query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PRODUCT_URL}?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getAllProducts = (token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PRODUCT_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Product | undefined> => {
  return axios
    .get(`${PRODUCT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}
const searchProduct = (search: string, token: string | undefined): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PRODUCT_URL}/search?name=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const deleteSelectedProduct = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${PRODUCT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
// const updateUser = (user: User): Promise<User | undefined> => {
//   return axios
//     .post(`${USER_URL}/${user.id}`, user)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }

export {getProducts, getAllProducts, getUserById, searchProduct, deleteSelectedProduct}
