import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {StoresQueryResponse, User} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const UPDATE_USERS_URL = `${API_URL}/user/update`
const GET_ALL_STORES = `${API_URL}/store`

const getUserById = (id: string, token: string | undefined): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User, token: string | undefined): Promise<User | undefined> => {
  return axios
    .put(`${UPDATE_USERS_URL}/${user._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      user,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}
const getStores = (token: string | undefined): Promise<StoresQueryResponse | undefined> => {
  return axios
    .get(`${GET_ALL_STORES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<StoresQueryResponse>) => d.data)
}
export {getUserById, updateUser, getStores}
