// import { Interface } from 'readline'
import { cloudURL } from './ApiUtils'

import AWS, { AWSError } from 'aws-sdk'
import { PutObjectAclRequest, PutObjectRequest } from 'aws-sdk/clients/s3'
import { PutObjectOutput, PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
// import { AnyNaptrRecord, AnyNsRecord } from 'dns'
// import { PutObjectResponse } from 'aws-sdk/clients/mediastoredata'
// export type FileType = {
//   file: {
//     lastModified?: number
//     lastModifiedDate?: object
//     name: string
//     size?: number
//     type?: string
//     webkitRelativePath?: string
//   }
// }
// export type FilesType = {
//   lastModified?: number
//   lastModifiedDate?: object
//   name: string
//   size?: number
//   type?: string
//   webkitRelativePath?: string
// }

export const s3 = new AWS.S3({
  accessKeyId: 'AKIARPCVHNPJQJ66XVX7',
  secretAccessKey: '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',
  region: 'ap-south-1',
})
export const uploadImageOnS3 = ({ file }) => {
  const uploadPromises = []
  console.log('file', file);
  const uploadParams = {
    Bucket: 'prestigeimages',
    Key: file.name,
    Body: file,
    ACL: 'public-read',
  }

  return new Promise((resolve, reject) => {
    s3.upload(uploadParams, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data.Location)
      }
    })
  })
}
export const uploadImagesToS3 = (files) => {
  const uploadPromises = [];
  console.log('this is the files in the upload images to s3', files[0]);
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    const uploadParams = {
      Bucket: "architez",
      Key: element.name,
      Body: element,
      ACL: "public-read",
    };

    uploadPromises.push(
      new Promise((resolve, reject) => {
        s3.upload(uploadParams, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      })
    );
  }


  return Promise.all(uploadPromises);
};



export const uploadDocToS3 = async (file, setState) => {
  const fileVal = file[0]
  const params = {
    Bucket: 'architez',
    Key: `${fileVal.name}`,
    Body: fileVal,
    ContentType: 'application/pdf',
  }

  await s3.upload(params, (err, data) => {
    if (err) {
      console.error(err)
    } else {
      console.log('upload success', data)
      setState(data.Location)
      return data.Location
    }
  })
}

export function convertNumber(number) {
  if (number >= 1000000000) {
    return number / 1000000000 + 'b'
  } else if (number >= 1000000) {
    return number / 1000000 + 'm'
  } else if (number >= 1000) {
    return number / 1000 + 'k'
  } else {
    return number
  }
}

// export const isNameOrEmail = (value) => {
//   // First, check if the value is an email by testing it against a regular expression for email validation
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//   if (emailRegex.test(value)) {
//     return 'email'
//   }

//   // If the value is not an email, assume it's a name by checking if it contains only letters, spaces, hyphens, or apostrophes
//   const nameRegex = /^[A-Za-z\s'-]+$/
//   if (nameRegex.test(value)) {
//     return 'name'
//   }

//   // If the value is neither an email nor a valid name, return null to indicate that the value is invalid
//   return null
// }

// export const checkInputType = (input) => {
//   // Use regular expression to check if input is a number
//   const numberRegex = /^[-+]?[0-9]+(\.[0-9]+)?$/

//   if (numberRegex.test(input)) {
//     return 'Number'
//   } else {
//     return 'String'
//   }
// }
