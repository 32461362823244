import {FC} from 'react'
import {Store} from '../../core/_models'

type Props = {
  branch?: Store
}

const EmployeTwoStepsCell: FC<Props> = ({branch}) => (
  <>
    <div className='fw-bolder'>{branch ? branch.storeName : ''}</div>
  </>
)

export {EmployeTwoStepsCell}
