import React from 'react'
import {ProfileDetails} from './cards/ProfileDetails'

export function EmployeePricing() {
  return (
    <>
      <ProfileDetails />
    </>
  )
}
