import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import {AccountHeader} from './AccountHeader'
import {EmployeePricing} from './components/employee-pricing/EmployeePricing'
import EmployeeNotes from './components/employee-notes/EmployeeNotes'
import ProductImages from './components/product-images/ProductImages'

const serviceDetailBreadCrumbs: Array<PageLink> = [
  {
    title: 'Service Details',
    path: '/apps/service-detail/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ServiceDetailPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={serviceDetailBreadCrumbs}>Service Details</PageTitle>
              <Overview />
            </>
          }
        />

        <Route
          path='employenotes'
          element={
            <>
              <PageTitle breadcrumbs={serviceDetailBreadCrumbs}>Employee notes</PageTitle>
              <EmployeeNotes />
            </>
          }
        />
        <Route
          path='estimatedpricing'
          element={
            <>
              <PageTitle breadcrumbs={serviceDetailBreadCrumbs}>Estimated pricing</PageTitle>
              <EmployeePricing />
            </>
          }
        />
        <Route
          path='edit'
          element={
            <>
              <PageTitle breadcrumbs={serviceDetailBreadCrumbs}>Edit</PageTitle>
              <Settings />
            </>
          }
        />
        <Route
          path='priductimages'
          element={
            <>
              <PageTitle breadcrumbs={serviceDetailBreadCrumbs}>Product images</PageTitle>
              <ProductImages />
            </>
          }
        />
        <Route index element={<Navigate to='/apps/service-detail/' />} />
      </Route>
    </Routes>
  )
}

export default ServiceDetailPage
