/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Product} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  user: Product
}

const ProductInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user?.productPicture && user?.productPicture.length > 0 ? (
          <div className='symbol-label'>
            <img src={user?.productPicture[0].Location} alt={'sample'} className='w-100' />
          </div>
        ) : (
          <div className='symbol-label'>
            <img
              src='https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
              alt='sample'
              className='w-100'
            />
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <Link
        to={`/apps/product-detail/${user?._id}/overview`}
        className='text-gray-800 text-hover-primary mb-1'
      >
        {user?.productName}
      </Link>
      <span>{user?.productModalNumber}</span>
    </div>
  </div>
)

export {ProductInfoCell}
