/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import CalendarFilter from './component/CalendarFilter'
import FilterByStoreAndEmp from './component/FilterByStoreAndEmp'
import {useGetTicketcCount, useGetTicketsData} from './component/core/QueryResponseProvider'
import {DashBoardCountAndDesc, DashBoardCounts, Service} from './component/core/_models'
import {useQueryRequest} from '../../modules/apps/employee-management/employes-list/core/QueryRequestProvider'
import {
  StartAndEndDateProvider,
  useStartAndEndDateProvider,
} from './component/core/context/Provider'
import {useAuth} from '../../modules/auth'
import {filterService, getAllServices} from './component/core/_requests'
const DashboardPage: FC = () => {
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const {currentUser} = useAuth()

  const {startDate, endDate, store, employee} = useStartAndEndDateProvider()
  // const {response, isFetching} = useGetTicketcCount()
  const {ticketsData, isFetching} = useGetTicketsData()
  const [serviceData, setServiceData] = useState<DashBoardCountAndDesc[]>()
  const [serviceRevenue, setServiceRevenue] = useState<DashBoardCountAndDesc[]>()
  console.log(ticketsData?.data)
  const calculateTicketsCount = (services: Service[]) => {
    console.log('pr meray pass tu service hai', services)
    let pendingService = 0
    let approvedService = 0
    let completedServiceByEmployee = 0
    let approvedByAdmin = 0
    let RejectedService = 0
    let Progress = 0
    if (services.length <= 0) {
      let totalService = services.length
      let data: DashBoardCountAndDesc[] = [
        {count: totalService, description: 'All Tickets'},
        {count: pendingService, description: 'Pending'},
        {count: Progress, description: 'Progress'},
        {count: approvedService, description: 'Approved'},
        {count: completedServiceByEmployee, description: 'Completed by employee'},
        {count: approvedByAdmin, description: 'Approved by admin'},
        {count: RejectedService, description: 'Rejected'},
      ]
      setServiceData(data)
      return data
    } else {
      for (let i = 0; i < services.length; i++) {
        const element = services[i]
        console.log('element', element)
        if (element.status === 'Pending') {
          pendingService++
        } else if (element.status === 'Approved') {
          approvedService++
        } else if (element.status === 'Progress') {
          Progress++
        } else if (element.status === 'Completed by employee') {
          completedServiceByEmployee++
        } else if (element.status === 'Approved by admin') {
          approvedByAdmin++
        } else if (element.status === 'Rejected') {
          RejectedService++
        }
      }
      let totalService = services.length
      let data: DashBoardCountAndDesc[] = [
        {count: totalService, description: 'All Tickets'},
        {count: pendingService, description: 'Pending'},
        {count: Progress, description: 'Progress'},
        {count: approvedService, description: 'Approved'},
        {count: completedServiceByEmployee, description: 'Completed by employee'},
        {count: approvedByAdmin, description: 'Approved by admin'},
        {count: RejectedService, description: 'Rejected'},
      ]
      setServiceData(data)
      return data
    }
  }
  const calculateRevenues = (services: Service[]) => {
    let totalRevenueRecieved = 0
    let pendingAmount = 0
    let estimatedBillingValue = 0
    if (services.length <= 0) {
      let data: DashBoardCountAndDesc[] = [
        {count: totalRevenueRecieved, description: 'Total Revenue Recieved'},
        {count: pendingAmount, description: 'Pending amounts'},
        {count: estimatedBillingValue, description: 'Estimated Billing Value'},
      ]
      return data
    } else {
      for (let i = 0; i < services.length; i++) {
        const element = services[i]
        if (element?.finalPricing) {
          totalRevenueRecieved = +element?.finalPricing + totalRevenueRecieved
        }
        if (element.estimatedPricing) {
          estimatedBillingValue = +element?.estimatedPricing + estimatedBillingValue
        }
      }
      let data: DashBoardCountAndDesc[] = [
        {count: totalRevenueRecieved, description: 'Total Revenue Recieved'},
        {count: pendingAmount, description: 'Pending amounts'},
        {count: estimatedBillingValue, description: 'Estimated Billing Value'},
      ]
      return data
    }
  }
  console.log('this is the service revenue', serviceRevenue)

  useEffect(() => {
    const filterServiceData = async () => {
      if (store || endDate || startDate || employee) {
        const data = await filterService({startDate, endDate, store, employee}, auth?.access_token)
        console.log('this is the filter data', data)
        if (data.data) {
          setServiceData(calculateTicketsCount(data.data))
          setServiceRevenue(calculateRevenues(data.data))
        }
      } else {
        const data = await getAllServices()
        console.log('this is the al service')
        if (data.data) {
          setServiceData(calculateTicketsCount(data.data))
          setServiceRevenue(calculateRevenues(data.data))
        }
      }
    }
    filterServiceData()
  }, [startDate, endDate, store, employee])
  if (isFetching) {
    return <div>Please wait</div>
  } else {
    return (
      <>
        {/* begin::Row */}
        <div className='d-flex justify-content-between mb-5'>
          <div className='fw-bold fs-3'>{`${
            currentUser && currentUser.user.is_admin ? 'Overall' : 'My'
          } Dashboard`}</div>
          <div className='d-flex '>
            <div className='fw-bold mb-2'>
              <CalendarFilter />
            </div>
            <div className='fw-bold mb-2'>
              {currentUser && currentUser.user.is_admin ? <FilterByStoreAndEmp /> : <></>}
            </div>
          </div>
        </div>
        <div className='d-flex w-100 flex-column  mb-3 mb-xl-7'>
          {/* begin::Col */}
          <div className='d-flex flex-wrap align-items-center gap-5 w-100 '>
            {serviceData?.map((res, i) => {
              return (
                <CardsWidget20
                  key={i}
                  count={res.count}
                  className='h-md-180px w-md-250px mb-3 mb-xl-5'
                  description={res.description}
                  color='#F1416C'
                  img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                />
              )
            })}
          </div>
          <div>
            <div className='fw-bold fs-3 mb-2'>Revenue</div>
            <div className='d-flex flex-wrap align-items-center gap-5 w-100 '>
              {serviceRevenue?.map((servRev, i) => {
                return (
                  <CardsWidget20
                    count={servRev.count}
                    key={i}
                    className='h-md-180px w-md-250px mb-3 mb-xl-5'
                    description={servRev.description}
                    color='#F1416C'
                    img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                  />
                )
              })}
            </div>
          </div>
        </div>
        {/* end::Row */}
      </>
    )
  }
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <StartAndEndDateProvider>
        <DashboardPage />
      </StartAndEndDateProvider>
    </>
  )
}

export {DashboardWrapper}
// report management sub menues
// revene reports
// like the tranacstion table

// we can excort in the excel format

// complaints reports
// compliant mangemet table
