/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'


type Props = {
  user: User
}
const BookedBy: FC<Props> = ({user}) => (
  <>
  {user.bookedBy?<div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.bookedBy?.profilePicture ? (
          <div className='symbol-label'>
            <img src={user.bookedBy?.profilePicture} alt={user.bookedBy?.fullName} className='w-100' />
          </div>
        ) : (
          <div className='symbol-label'>
            <img
              src={
                'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
              }
              alt={user?.bookedBy?.fullName}
              className='w-100'
            />
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {user.bookedBy?.fullName}
      </a>
      <span>{user.bookedBy?.phone}</span>
    </div>
  </div>:<div></div>}
  </>
)

export {BookedBy}
