import {FC} from 'react'

type Props = {
  repairClass?: string
}

const EmployeTwoStepsCell: FC<Props> = ({repairClass}) => (
  <>
    <div className='fw-bolder'>{repairClass}</div>
  </>
)

export {EmployeTwoStepsCell}
