/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'

import {useLocation} from 'react-router'
import {useGetEmployeeById, useUpdateEmployeeById} from './components/core/QueryResponseProvider'
import {updateUser} from './components/core/_request'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'

const AccountHeader: React.FC = () => {
  const location = useLocation()
  const {id} = useParams()
  const {auth} = useAuth()
  const {mutate} = useUpdateEmployeeById(id)
  const {data} = useGetEmployeeById(id)
  console.log('employee data', data)

  const handleActiveOrInactive = async (e: ChangeEvent) => {
    if (data) {
      delete data.password
      console.log(data.is_deactivated)
      data.is_deactivated = !data.is_deactivated
      console.log(data?.is_deactivated)

      try {
        await mutate(data)
        return toast.success(`update successfully`)
      } catch (error) {
        return toast.error('Something went wrong')
      }
    }
  }
  // useEffect(() => {}, [data])
  if (data) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              {data.profilePicture ? (
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={data?.profilePicture} alt='Metronic' />
                </div>
              ) : (
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img
                    src={
                      'https://zeevector.com/wp-content/uploads/2021/02/Prestige-Xclusive-Logo-PNG.png'
                    }
                    alt='Metronic'
                  />
                </div>
              )}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.fullName}
                    </a>
                    <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 p-4'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {data?.phone}
                    </a>
                  </div>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='activeOrInactive'
                      defaultChecked={!data?.is_deactivated}
                      onChange={(e) => handleActiveOrInactive(e)}
                    />
                    <label className='form-check-label'>
                      {!data?.is_deactivated ? 'Active' : 'InActive'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/employe-details/${id}/overview` && 'active')
                  }
                  to={`/apps/employe-details/${id}/overview`}
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/apps/employe-details/${id}/settings` && 'active')
                  }
                  to={`/apps/employe-details/${id}/settings`}
                >
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  } else {
    return <div>Data is fetching please wait</div>
  }
}

export {AccountHeader}
